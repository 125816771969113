import { Component, Input, OnChanges, OnInit } from '@angular/core'

export interface IBarChartInfo {
  colorClass: string
  size: number
}

@Component({
  selector: 'mg-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit, OnChanges {
  @Input() info: IBarChartInfo[] = []
  @Input() animatable = true
  sizes: number[]
  isVisible: boolean

  ngOnInit(): void {
    this.sizes = this.info.map(() => 0)
  }

  ngOnChanges(): void {
    this.setSizes()
  }

  setSizes() {
    if (!this.isVisible) {
      return
    }

    setTimeout(() => {
      this.sizes = this.info.map((el) => el.size)
    })
  }
}
