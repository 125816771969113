<swiper class="element-slider" [config]="config">
  <ng-container *ngIf="numberOfSlots === 1">
    <ng-template swiperSlide>
      <ng-content select="[slot=slide11]"></ng-content>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="numberOfSlots === 2">
    <ng-template swiperSlide>
      <ng-content select="[slot=slide21]"></ng-content>
    </ng-template>
    <ng-template swiperSlide>
      <ng-content select="[slot=slide22]"></ng-content>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="numberOfSlots === 3">
    <ng-template swiperSlide>
      <ng-content select="[slot=slide31]"></ng-content>
    </ng-template>
    <ng-template swiperSlide>
      <ng-content select="[slot=slide32]"></ng-content>
    </ng-template>
    <ng-template swiperSlide>
      <ng-content select="[slot=slide33]"></ng-content>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="numberOfSlots === 4">
    <ng-template swiperSlide>
      <ng-content select="[slot=slide41]"></ng-content>
    </ng-template>
    <ng-template swiperSlide>
      <ng-content select="[slot=slide42]"></ng-content>
    </ng-template>
    <ng-template swiperSlide>
      <ng-content select="[slot=slide43]"></ng-content>
    </ng-template>
    <ng-template swiperSlide>
      <ng-content select="[slot=slide44]"></ng-content>
    </ng-template>
  </ng-container>
  <ng-container *ngIf="numberOfSlots === 5">
    <ng-template swiperSlide>
      <ng-content select="[slot=slide51]"></ng-content>
    </ng-template>
    <ng-template swiperSlide>
      <ng-content select="[slot=slide52]"></ng-content>
    </ng-template>
    <ng-template swiperSlide>
      <ng-content select="[slot=slide53]"></ng-content>
    </ng-template>
    <ng-template swiperSlide>
      <ng-content select="[slot=slide54]"></ng-content>
    </ng-template>
    <ng-template swiperSlide>
      <ng-content select="[slot=slide55]"></ng-content>
    </ng-template>
  </ng-container>
</swiper>
