import { DayOfWeek } from '@mg-platform/core/core-data-access'

export interface IUpdateShopRequest {
  nickname?: string
  name: string
  phoneNumber: string
  websiteUrl: string
  workingDays?: DayOfWeek[]
}

export interface IAdminUpdateShopRequest extends IUpdateShopRequest {
  address: string
  city: string
  postCode: string
  stateId?: string
  latitude?: number
  longitude?: number
  numberOfBays?: number
}
