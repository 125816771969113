import { IShop } from './shop.interface'

export interface IShopGroup {
  id: string
  name: string
  shops: IShop[]
}

export interface IUpdateShopGroup {
  groupId?: string
  name: string
  shopIds: string[]
}
