import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { UntilDestroy } from '@ngneat/until-destroy'
import { IRangeDateInfo } from '../../interfaces/range-date.interface'
import { CalendarMaxDate, PeriodType } from '@mg-platform/core/core-data-access'

@UntilDestroy()
@Component({
  selector: 'mg-range-date-picker-dialog',
  templateUrl: './range-date-picker-dialog.component.html',
  styleUrls: ['./range-date-picker-dialog.component.scss']
})
export class RangeDatePickerDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RangeDatePickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      value: IRangeDateInfo
      maxDate: CalendarMaxDate
      hiddenPeriods: PeriodType[]
      hideAllPeriods: boolean
    }
  ) {}
}
