export interface IShopExtractedInfo {
  name?: string
  address?: string
  countryCode?: string
  city?: string
  postCode?: string
  stateAbbreviation?: string
  phoneNumber?: string
  websiteUrl?: string
  lat?: number
  long?: number
}
