import { PeriodType } from '@mg-platform/core/core-data-access'

export interface IRangeDateValue {
  from?: Date | string
  to?: Date | string
}

export interface IRangeDateInfo {
  rangeDate?: IRangeDateValue
  periodType?: PeriodType
}
