export interface ICreateShopRequest {
  nickname?: string
  name: string
  address: string
  city: string
  postCode: string
  phoneNumber: string
  websiteUrl: string
  lat?: number
  long?: number
  numberOfBays: number
  stateId: string
}
