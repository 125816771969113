import { Inject, Injectable } from '@angular/core'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { IS_PLATFORM_SERVER } from '../constants/platform.constant'
import { environment } from '../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AppInsightService {
  appInsights: ApplicationInsights
  constructor(@Inject(IS_PLATFORM_SERVER) private isServer: boolean) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true // log all route changes
      }
    })

    if ((environment.production || environment.name === 'dev') && !this.isServer) {
      this.appInsights.loadAppInsights()
    }
  }

  logPageView(name?: string, url?: string) {
    // option to call log route change manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    })
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.appInsights.trackEvent({ name: name }, properties)
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties)
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel
    })
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message }, properties)
  }
}
