import { Injectable, NgZone } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { ToastrService } from 'ngx-toastr'
import { mergeMap } from 'rxjs'
import { tap } from 'rxjs/operators'
import { IJWTToken, IUser } from '../../interfaces/user.interface'
import { UsersService } from '../../users.service'
import {
  GetCurrentUser,
  Login,
  Register,
  Logout,
  RequestPasswordReset,
  ResetPassword,
  UpdateProfile,
  UpdatePassword,
  GetUserPreferences,
  UpdateUserPreferences,
  SetReturnUrl,
  NavigateToReturnUrl,
  LoginAutovitals,
  SetReferralType,
  RefreshToken,
  SetTokenContent
} from './user-account.actions'
import { UserAccountStateModel } from './user-account.model'
import { LOCALSTORAGE_KEYS } from '@mg-platform/core/core-util'
import jwt_decode from 'jwt-decode'
import { isEqual, sortBy } from 'lodash'

@State<UserAccountStateModel>({
  name: 'userAccount'
})
@Injectable()
export class UserAccountState {
  constructor(
    private usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private store: Store,
    private toastrService: ToastrService
  ) {}

  @Selector()
  static isAuthenticated(state: UserAccountStateModel) {
    return state.isAuthenticated
  }

  @Selector()
  static currentUser(state: UserAccountStateModel) {
    return state.user
  }

  @Selector()
  static permissions(state: UserAccountStateModel) {
    return state.user?.permissions ?? []
  }

  @Selector()
  static userPreferences(state: UserAccountStateModel) {
    return state.userPreferences
  }

  @Selector()
  static referralType(state: UserAccountStateModel) {
    return state.referralType
  }

  @Action(Login)
  login(ctx: StateContext<UserAccountStateModel>, { payload }: Login) {
    return this.usersService.login(payload).pipe(
      mergeMap((res) => {
        this.setAuthToken(res.token)
        return ctx.dispatch(new GetCurrentUser())
      })
    )
  }

  @Action(LoginAutovitals)
  loginAutovitals(ctx: StateContext<UserAccountStateModel>, { payload }: LoginAutovitals) {
    return this.usersService.loginAutovitals(payload)
  }

  @Action(Logout)
  logout(ctx: StateContext<UserAccountStateModel>) {
    if (localStorage.getItem(LOCALSTORAGE_KEYS.JWT_TOKEN)) {
      localStorage.removeItem(LOCALSTORAGE_KEYS.JWT_TOKEN)
    }
    ctx.patchState({ user: undefined, isAuthenticated: false })
  }

  @Action(GetCurrentUser)
  getCurrentUser(ctx: StateContext<UserAccountStateModel>) {
    return this.usersService.getFullUserInformation().pipe(
      tap((res: IUser) => {
        const token = localStorage.getItem(LOCALSTORAGE_KEYS.JWT_TOKEN)
        if (!token) {
          return
        }
        const tokenContent = jwt_decode<IJWTToken>(token)

        ctx.patchState({
          user: {
            ...res,
            role: tokenContent.role,
            permissions: tokenContent.userPermissions,
            organizationFeaturePermissions: tokenContent.organizationFeaturePermissions,
            isOrganizationOwner: tokenContent.userIsOwner === 'true'
          },
          isAuthenticated: true
        })
      })
    )
  }

  @Action(Register)
  register(ctx: StateContext<UserAccountStateModel>, { payload }: Register) {
    const referralType = ctx.getState()?.referralType
    return this.usersService.register({ ...payload, referralType }).pipe(
      mergeMap((res) => {
        this.setAuthToken(res.token)
        return ctx.dispatch(new GetCurrentUser())
      })
    )
  }

  @Action(RequestPasswordReset)
  requestPasswordReset(ctx: StateContext<UserAccountStateModel>, { payload }: RequestPasswordReset) {
    return this.usersService.requestPasswordReset(payload)
  }

  @Action(ResetPassword)
  resetPassword(ctx: StateContext<UserAccountStateModel>, { payload }: ResetPassword) {
    return this.usersService.resetPassword(payload).pipe(
      mergeMap((res) => {
        this.setAuthToken(res.token)
        return ctx.dispatch(new GetCurrentUser())
      })
    )
  }

  @Action(UpdateProfile)
  updateProfile(ctx: StateContext<UserAccountStateModel>, { payload }: UpdateProfile) {
    return this.usersService
      .updateProfile(payload)
      .pipe(mergeMap(() => ctx.dispatch(new GetCurrentUser())))
  }

  @Action(UpdatePassword)
  updatePassword(ctx: StateContext<UserAccountStateModel>, { payload }: UpdatePassword) {
    return this.usersService.updatePassword(payload)
  }

  @Action(GetUserPreferences)
  getUserPreferences(ctx: StateContext<UserAccountStateModel>) {
    return this.usersService.getUserPreferences().pipe(
      tap((res) => {
        ctx.patchState({ userPreferences: res })
      })
    )
  }

  @Action(UpdateUserPreferences, { cancelUncompleted: true })
  updateUserPreferences(ctx: StateContext<UserAccountStateModel>, { payload }: UpdateUserPreferences) {
    return this.usersService.updateUserPreferences(payload)
  }

  @Action(SetReferralType)
  setReferralType(ctx: StateContext<UserAccountStateModel>, { payload }: SetReferralType) {
    ctx.patchState({ referralType: payload })
  }

  @Action(RefreshToken)
  refreshToken(ctx: StateContext<UserAccountStateModel>) {
    return this.usersService.refreshToken().pipe(
      tap((res) => {
        const user = ctx.getState()?.user
        if (!res.token || !user) {
          return
        }
        localStorage.setItem(LOCALSTORAGE_KEYS.JWT_TOKEN, res.token)

        this.store.dispatch(new SetTokenContent())
      })
    )
  }

  @Action(SetTokenContent)
  setTokenContent(ctx: StateContext<UserAccountStateModel>) {
    const user = ctx.getState()?.user
    const token = localStorage.getItem(LOCALSTORAGE_KEYS.JWT_TOKEN)

    if (!token || !user) {
      return
    }

    const tokenContent = jwt_decode<IJWTToken>(token)

    if (
      !isEqual(
        [...sortBy(user.organizationFeaturePermissions ?? [])],
        [...sortBy(tokenContent?.organizationFeaturePermissions ?? [])]
      )
    ) {
      document.location.href = '/'
      return
    }

    ctx.patchState({
      user: {
        ...user,
        role: tokenContent.role,
        permissions: tokenContent.userPermissions,
        organizationFeaturePermissions: tokenContent.organizationFeaturePermissions,
        isOrganizationOwner: tokenContent.userIsOwner === 'true'
      },
      isAuthenticated: true
    })
  }

  @Action(SetReturnUrl)
  setReturnUrl(ctx: StateContext<UserAccountStateModel>) {
    const { returnUrl } = ctx.getState()
    if (returnUrl && returnUrl !== '/') {
      return
    }
    const fromQuery: string = this.activatedRoute.snapshot.queryParams['returnUrl']
    if (
      fromQuery &&
      !(
        fromQuery.startsWith(`${window.location.origin}/`) ||
        /\/[^\\/].*/.test(fromQuery) ||
        fromQuery === '/'
      )
    ) {
      // This is an extra check to prevent open redirects.
      this.toastrService.error(
        'Invalid return url. The return url needs to have the same origin as the current page.'
      )
      ctx.patchState({
        returnUrl: '/'
      })
      return
    }

    ctx.patchState({
      returnUrl: fromQuery || '/'
    })
  }

  @Action(NavigateToReturnUrl)
  async navigateToReturnUrl(ctx: StateContext<UserAccountStateModel>) {
    await this.ngZone.run(() =>
      this.router.navigateByUrl(ctx.getState()?.returnUrl ?? '/', {
        replaceUrl: true
      })
    )
    ctx.patchState({
      returnUrl: undefined
    })
  }

  private setAuthToken(token: string) {
    localStorage.setItem(LOCALSTORAGE_KEYS.JWT_TOKEN, token)
  }
}
