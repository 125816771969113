import { IPaginationFilteredResponse } from '@mg-platform/core/core-feature'
import { IAdminSimpleShop } from '@mg-platform/shops/shops-data-access'
import { IBaseUser, OrganizationFeatures } from '@mg-platform/users/users-data-access'

export interface IUpdateOrganizationRequest {
  payedShops?: number
  notes?: string
  subscriptionExpireDate?: Date
  featurePermissions?: OrganizationFeatures[]
  isBlocked?: boolean
}

export interface IOrganizationBase {
  id: string
  name: string
  createdById: string
  created: Date
  notes: string
  subscriptionExpireDate: Date
  payedShops?: number
  isBlocked?: boolean
}

export interface IOrganizationItem extends IOrganizationBase {
  numberOfMembers: number
  numberOfShops: number
  lastReportDate?: Date
  lastLoginDate?: Date
}

export interface IAllOrganizationsResponse extends IPaginationFilteredResponse {
  organizations: IOrganizationItem[]
}

export interface IOrganizationDetails extends IOrganizationBase {
  members?: IBaseUser[]
  shops?: IAdminSimpleShop[]
  features?: OrganizationFeatures[]
}
