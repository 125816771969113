import { ISelectItem } from '@mg-platform/core/core-ui'

export enum NotificationType {
  Other = 'Other',
  FirstDataReminderEarly = 'FirstDataReminderEarly',
  SecondDataReminderEarly = 'SecondDataReminderEarly',
  General = 'General',
  MeVsMeAvgTicket = 'MeVsMeAvgTicket',
  MeVsMeAvgCarCount = 'MeVsMeAvgCarCount',
  MeVsMarketAvgTicket = 'MeVsMarketAvgTicket',
  MeVsMarketAvgCarCount = 'MeVsMarketAvgCarCount',
  ShopVerified = 'ShopVerified',
  FirstDataReminderLate = 'FirstDataReminderLate',
  SecondDataReminderLate = 'SecondDataReminderLate',
  ShopRequiresVerification = 'ShopRequiresVerification',
  ResetPassword = 'ResetPassword',
  ContactUs = 'ContactUs',
  Invitation = 'Invitation',
  WeeklySummary = 'WeeklySummary',
  MaxNumbersUpdated = 'MaxNumbersUpdated',
  GreenDaysCarCount = 'GreenDaysCarCount',
  RedDaysCarCount = 'RedDaysCarCount',
  GreenDaysAvgTicket = 'GreenDaysAvgTicket',
  RedDaysAvgTicket = 'RedDaysAvgTicket',
  MinMaxNumbersMarketPricing = 'MinMaxNumbersMarketPricing',
  State4WeeksTrend = 'State4WeeksTrend',
  GoalPacing = 'GoalPacing',
  CompSales = 'CompSales',
  StateMomentum = 'StateMomentum'
}

export const NotificationTypeToLabel: { [key: string]: string } = {
  Other: 'Other',
  FirstDataReminderEarly: 'First data reminder early',
  SecondDataReminderEarly: 'Second data reminder early',
  General: 'General',
  MeVsMeAvgTicket: 'Me vs Me avg ticket',
  MeVsMeAvgCarCount: 'Me vs Me avg car count',
  MeVsMarketAvgTicket: 'Me vs Market avg ticket',
  MeVsMarketAvgCarCount: 'Me vs Market avg car count',
  ShopVerified: 'Shop verified',
  FirstDataReminderLate: 'First data reminder late',
  SecondDataReminderLate: 'Second data reminder late',
  ShopRequiresVerification: 'Shop requires verification',
  ResetPassword: 'Reset password',
  ContactUs: 'Contact us',
  Invitation: 'Invitation',
  WeeklySummary: 'Weekly summary',
  MaxNumbersUpdated: 'Max numbers updated',
  GreenDaysCarCount: 'Green days car count',
  RedDaysCarCount: 'Red days car count',
  GreenDaysAvgTicket: 'Green days avg ticket',
  RedDaysAvgTicket: 'Red days avg ticket',
  MinMaxNumbersMarketPricing: 'MinMax numbers market pricing',
  State4WeeksTrend: 'State 4 weeks trend',
  GoalPacing: 'Goal pacing',
  CompSales: 'Comp sales',
  StateMomentum: 'State momentum'
}

export const NotificationTypeToList: ISelectItem[] = [
  {
    label: NotificationTypeToLabel['Other'],
    value: NotificationType.Other
  },
  {
    label: NotificationTypeToLabel['FirstDataReminderEarly'],
    value: NotificationType.FirstDataReminderEarly
  },
  {
    label: NotificationTypeToLabel['SecondDataReminderEarly'],
    value: NotificationType.SecondDataReminderEarly
  },
  {
    label: NotificationTypeToLabel['General'],
    value: NotificationType.General
  },
  {
    label: NotificationTypeToLabel['MeVsMeAvgTicket'],
    value: NotificationType.MeVsMeAvgTicket
  },
  {
    label: NotificationTypeToLabel['MeVsMeAvgCarCount'],
    value: NotificationType.MeVsMeAvgCarCount
  },
  {
    label: NotificationTypeToLabel['MeVsMarketAvgTicket'],
    value: NotificationType.MeVsMarketAvgTicket
  },
  {
    label: NotificationTypeToLabel['MeVsMarketAvgCarCount'],
    value: NotificationType.MeVsMarketAvgCarCount
  },
  {
    label: NotificationTypeToLabel['ShopVerified'],
    value: NotificationType.ShopVerified
  },
  {
    label: NotificationTypeToLabel['FirstDataReminderLate'],
    value: NotificationType.FirstDataReminderLate
  },
  {
    label: NotificationTypeToLabel['SecondDataReminderLate'],
    value: NotificationType.SecondDataReminderLate
  },
  {
    label: NotificationTypeToLabel['ShopRequiresVerification'],
    value: NotificationType.ShopRequiresVerification
  },
  {
    label: NotificationTypeToLabel['ResetPassword'],
    value: NotificationType.ResetPassword
  },
  {
    label: NotificationTypeToLabel['ContactUs'],
    value: NotificationType.ContactUs
  },
  {
    label: NotificationTypeToLabel['Invitation'],
    value: NotificationType.Invitation
  },
  {
    label: NotificationTypeToLabel['WeeklySummary'],
    value: NotificationType.WeeklySummary
  },
  {
    label: NotificationTypeToLabel['MaxNumbersUpdated'],
    value: NotificationType.MaxNumbersUpdated
  },
  {
    label: NotificationTypeToLabel['GreenDaysCarCount'],
    value: NotificationType.GreenDaysCarCount
  },
  {
    label: NotificationTypeToLabel['RedDaysCarCount'],
    value: NotificationType.RedDaysCarCount
  },
  {
    label: NotificationTypeToLabel['GreenDaysAvgTicket'],
    value: NotificationType.GreenDaysAvgTicket
  },
  {
    label: NotificationTypeToLabel['RedDaysAvgTicket'],
    value: NotificationType.RedDaysAvgTicket
  },
  {
    label: NotificationTypeToLabel['MinMaxNumbersMarketPricing'],
    value: NotificationType.MinMaxNumbersMarketPricing
  },
  {
    label: NotificationTypeToLabel['State4WeeksTrend'],
    value: NotificationType.State4WeeksTrend
  },
  {
    label: NotificationTypeToLabel['GoalPacing'],
    value: NotificationType.GoalPacing
  },
  {
    label: NotificationTypeToLabel['CompSales'],
    value: NotificationType.CompSales
  },
  {
    label: NotificationTypeToLabel['StateMomentum'],
    value: NotificationType.StateMomentum
  }
]
