import { ISimpleShop } from '@mg-platform/shops/shops-data-access'
import { Role } from '../enums/role.enum'
import { IBaseUser } from './user.interface'

export interface IHandleInvitationRequest {
  token: string
  accepted: boolean
}

export interface IInvitedUserStatus {
  isUserExist: boolean
  invitedFullName: string
  shopName: string
  ownerFullName: string
  errorCodes: {
    InvitationArchived: string
  }
}

export interface IInvitationToken {
  userEmail: string
  role: Role
}

export interface IInviteUserRequest {
  shopId: string
  email: string
  fullName: string
}

export interface IInvitationResponse {
  shop?: ISimpleShop
  user?: IBaseUser
}
