import { IUpdateAdminUserRequest } from '../../interfaces/admin-users.interface'
import { ITableAction } from '@mg-platform/core/core-feature'

export class GetAllAdminUsers {
  static readonly type = '[AdminUsers] Get All Admin Users'
  constructor(
    public info: ITableAction,
    public organizationId?: string,
    public shopId?: string
  ) {}
}

export class GetOneAdminUser {
  static readonly type = '[AdminUsers] Get One Admin User'
  constructor(public userId: string) {}
}

export class UpdateAdminUser {
  static readonly type = '[AdminUsers] Update Admin User'
  constructor(
    public userId: string,
    public payload: IUpdateAdminUserRequest
  ) {}
}
