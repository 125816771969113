import { ChartOptions, PluginOptionsByType } from 'chart.js'
import { DeepPartial } from 'chart.js/types/utils'

interface IChartPlugins extends DeepPartial<PluginOptionsByType<'line'>> {
  crosshair?: any
}
export interface IChartData extends ChartOptions<'line'> {
  plugins: IChartPlugins
}

export interface ITrendsChartData {
  data: Array<{ data1: number; data2: number; label: string }>
  colors: string[]
  borderWidth: number[]
  performing?: boolean
  hideTrendsPointOnHover: boolean
}
