export type CompSalesReportType = 'monthly' | 'daily'

export interface IComparePeriodItemDetails {
  period: number
  label?: string
  benchmark: any
}

export interface IComparePeriodItem {
  period: number
  label: string
  currentPeriod?: IComparePeriodItemDetails
  previousPeriod?: IComparePeriodItemDetails
  percentageDifference?: number
  shopIds?: string[]
  shopsCount?: number
}

export interface ICompareBenchmarkItem {
  title: string
  subtitle: string
  historySubtitle: string
  avgSales: IComparePeriodItem[]
  avgCarCount: IComparePeriodItem[]
  avgTicket: IComparePeriodItem[]
}

export interface ICompareResponse {
  title: string
  subtitle: string
  dayBenchmark: ICompareBenchmarkItem
  monthBenchmark: ICompareBenchmarkItem
}

export interface ICompSalesFilters {
  avgSalesPerDay?: boolean
  avgCarPerDay?: boolean
  avgTicket?: boolean
}
