import { NotificationChannel } from '../enums/notification-channel.enum'
import { NotificationType } from '../enums/notification-type.enum'

export interface IUpdateUserNotificationsRequest {
  notificationTypeId: string
  sendOrganizationAggregate: boolean
  sendIndividuals: boolean
  notificationProperties: string[]
}

export interface IUserNotificationsItem {
  notificationTypeId: string
  sendIndividuals: boolean
  title: string
  subtitle: string
  channelType: NotificationChannel
  hourOfDay: string
  sendOrganizationAggregate: boolean
  notificationProperties: string[]
  code: NotificationType
}

export interface IUserNotificationsResponse {
  items: IUserNotificationsItem[]
}
