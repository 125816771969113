<ng-container *ngIf="!gridLoading">
  <div class="mt-2xl pb-2xl {{ containerClass }}" *ngFor="let item of configExtended">
    <div [ngClass]="{ 'mb-base': item.showTitles || item.showSubTitles }">
      <div *ngIf="item.showTitles" class="mg-loading-item loading-title"></div>
      <ng-container *ngIf="item.showSubTitles">
        <div class="mg-loading-item loading-subtitle"></div>
        <div class="mg-loading-item loading-subtitle-fixed"></div>
      </ng-container>
    </div>

    <div
      class="hidden"
      [ngClass]="{
        'md:flex gap-2xl': item.direction === 'horizontal',
        'md:block': item.direction === 'vertical'
      }"
    >
      <div
        *ngFor="let card of item.cards"
        class="mg-loading-item loading-card {{ itemClass }}"
        [style.height.px]="item.height"
        [ngClass]="{
          'mt-lg md:mt-0': item.direction === 'horizontal',
          'mt-2xl first:mt-lg': item.direction === 'vertical'
        }"
      ></div>
    </div>

    <div class="flex md:hidden mt-lg -mx-xl pl-xl {{ mobileItemClass }}">
      <div class="mg-loading-item loading-card" [style.height.px]="item.mobileHeight"></div>
      <div
        class="loading-card-small"
        style="width: calc(100vw / 40)"
        [style.height.px]="item.mobileHeight"
      ></div>
    </div>
    <div class="md:hidden flex items-center justify-center mt-lg">
      <div class="mg-loading-item w-[56px] h-2xl rounded-base"></div>
    </div>
  </div>
</ng-container>

<div *ngIf="gridLoading" class="grid {{ gridLayout }}">
  <div
    class="mg-card mg-loading-item"
    *ngFor="let item of gridItemsList"
    [style.height.px]="gridItemHeight"
  ></div>
</div>
