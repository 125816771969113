import { MarketType } from '../enums/market-type.enum'

export interface IMarketPricingRequest {
  laborRate: number
  alingment: number
  oilChange: number
}

export interface IMarketPricingSummary {
  alignmentYours: number
  alignmentAverage: number
  alignmentHigh: number
  alignmentLow: number
  laborRateYours: number
  laborRateAverage: number
  laborRateHigh: number
  laborRateLow: number
  oilChangeYours: number
  oilChangeAverage: number
  oilChangeHigh: number
  oilChangeLow: number
  alignmentYoursFormatted: string
  alignmentAverageFormatted: string
  alignmentHighFormatted: string
  alignmentLowFormatted: string
  laborRateYoursFormatted: string
  laborRateAverageFormatted: string
  laborRateHighFormatted: string
  laborRateLowFormatted: string
  oilChangeYoursFormatted: string
  oilChangeAverageFormatted: string
  oilChangeHighFormatted: string
  oilChangeLowFormatted: string
}

export interface IMarketPricingAverage {
  id: string
  displayText: string
  marketType: MarketType
  alignmentAverage: number
  laborRateAverage: number
  oilChangeAverage: number
  alignmentAverageFormatted: string
  laborRateAverageFormatted: string
  oilChangeAverageFormatted: string
  alignmentAverageBarPercentage: number
  laborRateAverageBarPercentage: number
  oilChangeAverageBarPercentage: number
}

export interface IMarketPricingItem {
  id: string
  marketType: MarketType
  displayText: string
  countryId: string
  value: number
  valueFormatted: string
  barPercentage: number
}
export interface IMarketPricingInfo {
  yours: number
  yoursFormatted: string
  average: number
  averageFormatted: string
  low: number
  lowFormatted: string
  high: number
  highFormatted: string
  items: IMarketPricingItem[]
}

export interface IMarketPricingResponse {
  summary: IMarketPricingSummary
  marketPricingDetails: IMarketPricingAverage[]
}

export interface IMarketPricingThresholds {
  minAlignment: number
  maxAlignment: number
  minLaborRate: number
  maxLaborRate: number
  minOilChange: number
  maxOilChange: number
}
