<div class="p-sm flex items-center bg-ui-bg rounded-sm" [ngClass]="{ '!pl-xs': hasMarketTypeSorting }">
  <div
    *ngIf="hasMarketTypeSorting"
    class="mr-sm cursor-pointer"
    (click)="sortChanged.emit(iconInfo.key)"
  >
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.6875 11.4375L11.6667 14.6458L14.6458 11.4375"
        [attr.stroke]="iconAscColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.354 4.56234L4.33317 1.354L7.31234 4.56234"
        [attr.stroke]="iconDescColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.6665 5.021V14.021"
        [attr.stroke]="iconAscColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.33301 1.354V10.354"
        [attr.stroke]="iconDescColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
  <div class="flex-1 flex items-center justify-between">
    <button
      *ngFor="let item of sortItems"
      class="cursor-pointer flex items-center"
      (click)="sortChanged.emit(item.value)"
    >
      <span class="pr-xs text-base leading-base text-txt">{{ item.label }}</span>
      <div
        *ngIf="
          sortInfo &&
          sortInfo.active === item.value &&
          sortInfo.direction &&
          $any(sortInfo.direction) !== ''
        "
        class="flex text-txt-secondary"
        [ngClass]="{ 'rotate-180': sortInfo.direction === 'asc' }"
      >
        <svg-icon key="arrow-up-full" class="!text-xs"></svg-icon>
      </div>
    </button>
  </div>
</div>
