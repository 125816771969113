import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { Sort } from '@angular/material/sort'
import { ISelectItem } from '@mg-platform/core/core-ui'

export const MarketTypeSortKey = 'marketType'

@UntilDestroy()
@Component({
  selector: 'mg-card-sort',
  templateUrl: './card-sort.component.html',
  styleUrls: ['./card-sort.component.scss']
})
export class CardSortComponent implements OnChanges {
  @Input() sortInfo?: Sort
  @Input() hasMarketTypeSorting = true
  @Input() sortItems: ISelectItem[] = []

  @Output() sortChanged = new EventEmitter<string | undefined>()

  iconInfo = {
    key: MarketTypeSortKey,
    colors: {
      normal: 'rgba(var(--color-txt-secondary),1)',
      active: 'rgba(var(--color-txt),1)',
      deactive: 'rgba(var(--color-txt-disabled),1)'
    }
  }

  iconAscColor = this.iconInfo.colors.normal
  iconDescColor = this.iconInfo.colors.normal

  ngOnChanges(): void {
    if (this.sortInfo && this.sortInfo.active === this.iconInfo.key && this.sortInfo.direction !== '') {
      if (this.sortInfo.direction === 'desc') {
        this.iconAscColor = this.iconInfo.colors.deactive
        this.iconDescColor = this.iconInfo.colors.active
      } else {
        this.iconAscColor = this.iconInfo.colors.active
        this.iconDescColor = this.iconInfo.colors.deactive
      }
    } else {
      this.iconAscColor = this.iconInfo.colors.normal
      this.iconDescColor = this.iconInfo.colors.normal
    }
  }
}
