import { ISelectItem } from '@mg-platform/core/core-ui'

export enum PosType {
  NotSet = 'NotSet',
  Protractor = 'Protractor',
  Tcs = 'Tcs',
  SimplyGenius = 'SimplyGenius',
  Autovitals = 'Autovitals',
  ProtractorBatch = 'ProtractorBatch',
  Tekmetric = 'Tekmetric'
}

export const PosTypeToLabel: { [key: string]: string } = {
  NotSet: 'Not Set',
  Protractor: 'Protractor',
  Tcs: 'Tcs',
  SimplyGenius: 'Simply Genius',
  Autovitals: 'Autovitals',
  ProtractorBatch: 'Protractor Batch',
  Tekmetric: 'Tekmetric'
}

export const PosTypeToList: ISelectItem[] = [
  { label: PosTypeToLabel[PosType.NotSet], value: PosType.NotSet },
  { label: PosTypeToLabel[PosType.Protractor], value: PosType.Protractor },
  { label: PosTypeToLabel[PosType.SimplyGenius], value: PosType.SimplyGenius },
  { label: PosTypeToLabel[PosType.Tekmetric], value: PosType.Tekmetric }
]
