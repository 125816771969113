import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ControlContainer, UntypedFormControl } from '@angular/forms'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Input() controlName: string
  @Input() placeholder = ''
  @Input() autoFocus = false
  @Input() iconColor = '#112027'
  @Input() iconSize = '14px'
  @Input() isLoading = false

  @Output() valueChanged = new EventEmitter()
  @Output() filterButtonClicked = new EventEmitter<string>()

  formValue: string

  formControl: UntypedFormControl
  constructor(private controlContainer: ControlContainer) {}

  ngOnInit(): void {
    this.formControl = this.controlContainer.control?.get(this.controlName) as UntypedFormControl
    this.formValue = this.formControl?.value

    this.formControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.formValue = value
      this.valueChanged.emit(value)
    })
  }
}
