import { Component, Input, OnInit } from '@angular/core'

export interface ILoadingConfig {
  cards: number
  height: number
  mobileHeight: number
  direction: 'horizontal' | 'vertical'
  showTitles?: boolean
  showSubTitles?: boolean
}

interface ILoadingConfigExtended {
  cards: number[]
  height: number
  mobileHeight: number
  direction: 'horizontal' | 'vertical'
  showTitles: boolean
  showSubTitles: boolean
}

@Component({
  selector: 'mg-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input() containerClass = ''
  @Input() mobileItemClass = ''
  @Input() itemClass = ''
  @Input() config: ILoadingConfig[] = []
  configExtended: ILoadingConfigExtended[] = []

  @Input() gridLoading = false
  @Input() gridLayout = ''
  @Input() numberOfGridItem = 0
  @Input() gridItemHeight = 0
  gridItemsList: number[] = []

  ngOnInit(): void {
    this.gridItemsList = Array(this.numberOfGridItem).fill(1)
    this.configExtended = this.config.map((el) => ({
      ...el,
      showTitles: el.showTitles !== undefined ? el.showTitles : true,
      showSubTitles: el.showSubTitles !== undefined ? el.showSubTitles : true,
      cards: Array(el.cards)
    }))
  }
}
