<mg-input
  class="w-full"
  type="google-places"
  [controlName]="controlName"
  [label]="label"
  [placeholder]="placeholder"
  (googlePlaceAddressChanged)="handleAddressChange($event)"
  [loading]="loading"
  [googlePlacesCountries]="googlePlacesCountries"
></mg-input>

<div class="flex items-end">
  <div class="mr-xs text-base leading-base font-semibold text-txt-secondary">powered by</div>
  <img class="mt-xs" src="assets/images/Powered_by_google.svg" />
</div>
