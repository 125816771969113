import { ICompareWithSelection } from './compare-with.interface'

export interface ITimeBaseRequest {
  startDate?: string | Date
  endDate?: string | Date
}

export interface IBaseRequest {
  primary: Partial<ICompareWithSelection>
  secondary: ICompareWithSelection
}

export interface IExtendedRequest extends IBaseRequest, ITimeBaseRequest {
  preselected?: string
}
