import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { CommonService, ThemeModeService } from '@mg-platform/core/core-util'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'

export interface IAnimatblePieChartItem {
  value: number
  color: string
  isGradiant?: boolean
}

@UntilDestroy()
@Component({
  selector: 'mg-ring-chart-animatable',
  templateUrl: './ring-chart-animatable.component.html',
  styleUrls: ['./ring-chart-animatable.component.scss']
})
export class RingChartAnimatableComponent implements OnInit, OnDestroy {
  @Input() size = 200
  @Input() ringWidth = 10
  @Input() numberOfCircles = 200
  @Input() item: IAnimatblePieChartItem

  randomId = ''
  intervalId: number
  isVisible = false
  isDraw = false

  constructor(private commonService: CommonService, private themeModeService: ThemeModeService) {}

  ngOnInit(): void {
    this.numberOfCircles = this.numberOfCircles < 100 ? 100 : this.numberOfCircles
    this.randomId = this.commonService.generateRandomString()

    this.themeModeService.isDarkMode.pipe(untilDestroyed(this)).subscribe(() => {
      const ringValueContainer = document.getElementById(
        `ringValueContainer${this.randomId}`
      ) as HTMLElement
      if (ringValueContainer) {
        const circleElements = ringValueContainer.children
        for (let index = 0; index < circleElements.length; index++) {
          const circle = circleElements[index]
          circle.setAttribute('fill', this.getFillColor(index))
        }
      }
    })
  }

  drawRing() {
    if (!this.isVisible || this.isDraw) {
      return
    }
    this.isDraw = true

    setTimeout(() => {
      const svgContaienr = document.getElementById(`svgContainer${this.randomId}`)
      if (!svgContaienr) {
        return
      }
      const basePath = document.getElementById(`path${this.randomId}`) as any as SVGPathElement
      const circumference = basePath?.getTotalLength() ?? 0
      const unit = circumference / this.numberOfCircles
      const circles: SVGCircleElement[] = []

      for (let i = 0; i < this.numberOfCircles; i++) {
        const pos = basePath.getPointAtLength(i * unit)
        const options = {
          cx: pos.x,
          cy: pos.y,
          r: this.ringWidth / 2,
          'fill-opacity': 0,
          fill: this.getFillColor(i)
        }
        circles.push(this.drawCircle(options))
      }

      let value = 0
      const numberOfMoves = this.item.value * (this.numberOfCircles / 100)
      this.intervalId = setInterval(() => {
        if (value > numberOfMoves) {
          clearInterval(this.intervalId)
          return
        }
        value += 1
        if (value > this.numberOfCircles - 1) {
          const degDiffernce = value - this.numberOfCircles
          const deg = (degDiffernce * 360) / this.numberOfCircles
          svgContaienr.style.transform = `rotate(${deg}deg)`
        } else {
          circles[value].setAttributeNS(null, 'fill-opacity', '1')
        }
      }, 5)
    })
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }

  drawCircle(options: any) {
    const SVG_NS = 'http://www.w3.org/2000/svg'
    const parent = document.getElementById(`ringValueContainer${this.randomId}`) as HTMLElement
    const circle = document.createElementNS(SVG_NS, 'circle')
    for (const name in options) {
      if (Object.prototype.hasOwnProperty.call(options, name)) {
        circle.setAttributeNS(null, name, options[name])
      }
    }
    parent.appendChild(circle)
    return circle
  }

  getFillColor(index: number) {
    return `hsl(${this.themeModeService.cssvar(this.item.color)},${
      Number(this.themeModeService.cssvar(this.item.color + '-start-point')) + (100 - index) / 12
    }%)`
  }
}
