import { ISelectItem } from '@mg-platform/core/core-ui'

export enum ShopType {
  Verified = 'Verified',
  Pending = 'Pending',
  Ignored = 'Ignored'
}

export const ShopTypeToLabel: { [key: string]: string } = {
  Verified: 'Verified',
  Pending: 'Pending',
  Ignored: 'Ignored'
}

export const ShopTypeToList: ISelectItem[] = [
  { label: ShopTypeToLabel[ShopType.Verified], value: ShopType.Verified },
  { label: ShopTypeToLabel[ShopType.Pending], value: ShopType.Pending },
  { label: ShopTypeToLabel[ShopType.Ignored], value: ShopType.Ignored }
]
