import { Injectable } from '@angular/core'
import { HttpService, IExportFileResponse } from '@mg-platform/core/core-data-access'
import { Observable } from 'rxjs'
import { IAllNotificationsResponse } from './interfaces/notifications.interface'
import { NOTIFICATIONS_API_URL } from './notifications-api-routes'
import {
  IUpdateUserNotificationsRequest,
  IUserNotificationsResponse
} from './interfaces/user-notifications.interface'
import { ITableAction, TableActionsService } from '@mg-platform/core/core-feature'

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(
    private http: HttpService,
    private tableActionsService: TableActionsService
  ) {}

  getAllNotifications(info: ITableAction): Observable<IAllNotificationsResponse> {
    return this.http.post<IAllNotificationsResponse>(
      NOTIFICATIONS_API_URL.getAllNotifications,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  exportAllNotifications(info: ITableAction): Observable<IExportFileResponse> {
    return this.http.post(
      NOTIFICATIONS_API_URL.exportAllNotifications,
      this.tableActionsService.createRequestPayload(info)
    )
  }

  getUserNotificationsConfig(): Observable<IUserNotificationsResponse> {
    return this.http.get<IUserNotificationsResponse>(NOTIFICATIONS_API_URL.getUserNotificationsConfig)
  }

  updateUserNotificationsConfig(payload: IUpdateUserNotificationsRequest) {
    return this.http.post(NOTIFICATIONS_API_URL.updateUserNotificationsConfig, payload)
  }
}
