import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core'

@Component({
  selector: 'mg-circle-chart',
  templateUrl: './circle-chart.component.html',
  styleUrls: ['./circle-chart.component.scss']
})
export class CircleChartComponent implements AfterViewInit, OnChanges {
  @Input() precentage = 50
  @Input() label = ''
  @Input() labelClass = ''
  @Input() shop = 100
  @Input() market = 50
  @Input() negativeColor = '#D32F2F'
  @Input() posetiveColor = '#0078D4'
  @ViewChild('line1') line1: ElementRef
  @ViewChild('line2') line2: ElementRef

  ngOnChanges() {
    this.drawChart()
  }

  ngAfterViewInit() {
    this.drawChart()
  }

  drawChart() {
    if (!this.line1 || !this.line2) {
      return
    }

    const max = +this.shop > +this.market ? this.shop : this.market
    const line1TotalLength = this.line1.nativeElement.getTotalLength()
    const line2TotalLength = this.line2.nativeElement.getTotalLength()
    const line1Length = line1TotalLength * (this.shop / max)
    const line2Length = line2TotalLength * (this.market / max)
    this.line1.nativeElement.style.strokeDasharray = line1Length + ' ' + line1TotalLength
    this.line1.nativeElement.style.strokeDashoffset = line1Length
    this.line2.nativeElement.style.strokeDasharray = line2Length + ' ' + line2TotalLength
    this.line2.nativeElement.style.strokeDashoffset = line2Length
  }

  calculateColor() {
    if (this.precentage < 0) {
      return this.negativeColor
    }
    return this.posetiveColor
  }
}
