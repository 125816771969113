import { environment } from '@mg-platform/core/core-util'

export const NOTIFICATIONS_API_URL = {
  // Admin
  getAllNotifications: `${environment.apiUrl}/admin/filters/notifications`,
  exportAllNotifications: `${environment.apiUrl}/admin/filters/notifications/export`,

  // User
  getUserNotificationsConfig: `${environment.apiUrl}/users/notifications-configurations`,
  updateUserNotificationsConfig: `${environment.apiUrl}/users/notifications-create-update`
}
