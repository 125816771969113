<div class="{{ containerClass }} border border-gray-300 rounded-sm flex items-stretch">
  <div
    *ngFor="let item of buttons"
    (click)="selectedButtonValue = item.value; selectedButtonChanged.emit(item.value)"
    class="button-item {{ itemClass }}"
    [ngClass]="{
      'bg-primary text-white': selectedButtonValue === item.value,
      'hover:bg-ui-hover-1 text-txt': selectedButtonValue !== item.value
    }"
  >
    {{ item.label }}
  </div>
</div>
