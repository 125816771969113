import { MarketType } from '../enums/market-type.enum'
import { TrendIntervalType } from '../enums/trend-interval-type.enum'
import {
  IAverageTrendData,
  IAverageTrendDataExtended,
  ITrendIntervalLabels
} from './average-trends.interface'

export interface IAverageTireTrendsSummary {
  id: string
  displayText: string
  marketType: MarketType
  countryId: string
  trendIntervalType: TrendIntervalType
  tireSales: IAverageTrendData
}

export interface IAverageTireTrendsSummaryExtended {
  tireSales: IAverageTrendDataExtended[]
}

export interface IAverageTireTrendsResponse {
  averageTireTrendSummaries: IAverageTireTrendsSummary[]
  trendIntervalLabels: ITrendIntervalLabels[]
  trendsCustomInterval: number
}

export interface IAverageTireTrendsResponseExtended {
  fourWeeks: IAverageTireTrendsSummaryExtended
  eightWeeks: IAverageTireTrendsSummaryExtended
  customWeeks: IAverageTireTrendsSummaryExtended
  trendsCustomInterval: number
}
