import { ISelectItem } from '@mg-platform/core/core-ui'

export enum NotificationChannel {
  NotSet = 'NotSet',
  Push = 'Push',
  Sms = 'Sms',
  Email = 'Email',
  Slack = 'Slack'
}

export const NotificationChannelToLabel: { [key: string]: string } = {
  NotSet: 'Not Set',
  Push: 'Push',
  Sms: 'SMS',
  Email: 'Email',
  Slack: 'Slack'
}

export const NotificationChannelToList: ISelectItem[] = [
  {
    label: NotificationChannelToLabel['NotSet'],
    value: NotificationChannel.NotSet
  },
  {
    label: NotificationChannelToLabel['Push'],
    value: NotificationChannel.Push
  },
  {
    label: NotificationChannelToLabel['Sms'],
    value: NotificationChannel.Sms
  },
  {
    label: NotificationChannelToLabel['Email'],
    value: NotificationChannel.Email
  },
  {
    label: NotificationChannelToLabel['Slack'],
    value: NotificationChannel.Slack
  }
]
