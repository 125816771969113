<div
  class="my-xl md:my-2xl flex flex-col md:flex-row items-center print:flex-row flex-wrap {{
    extraClass
  }}"
>
  <div
    class="flex-1 flex flex-col md:pr-base w-full"
    [ngClass]="{ 'mb-lg md:mb-0': contentSlot.children.length }"
  >
    <div class="text-xl leading-xl font-semibold text-txt capitalize">{{ title }}</div>
    <div #subTitleSlot>
      <ng-content select="[slot=subTitle]"></ng-content>
    </div>
    <div
      *ngIf="!subTitleSlot.children.length && subTitle"
      class="mt-2xs text-base leading-base text-txt"
    >
      {{ subTitle }}
    </div>
  </div>

  <div class="w-full md:w-auto" #contentSlot>
    <ng-content></ng-content>
  </div>
</div>
