import { ISimpleShop } from '@mg-platform/shops/shops-data-access'

export interface IDailyReportResponse {
  id: string
  day: Date
  notWorking: boolean
  vehicleInvoiced: number
  income: number
}

export interface IMissingDaysResponse {
  shop: ISimpleShop
  dates: Date[]
}

export interface IAddDailyReportRequest {
  shopId: string
  day: Date
  notWorking: boolean
  vehicleInvoiced: number
  income: number
}

export interface IUpdateDailyReportRequest {
  id: string
  notWorking: boolean
  vehicleInvoiced: number
  income: number
}
