<div class="md:hidden mb-2xl text-base font-semibold leading-base">Select Week</div>

<div class="flex flex-col gap-base md:gap-xs">
  <div
    *ngFor="let item of availableWeeks"
    class="w-full p-sm rounded-sm text-base leading-base text-txt hover:bg-ui-hover cursor-pointer"
    [ngClass]="{
      '!bg-primary !text-white': item.value === selectedWeekNumber
    }"
    (click)="selectedWeekChanged.emit(item.value)"
  >
    {{ item.label }}
  </div>
</div>
