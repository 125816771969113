import { Component } from '@angular/core'
import { IHeaderRouteInfo } from '@mg-platform/core/core-feature'
import { ApplicationRoutes } from '../../../app-routes-names'
import { ProfilePaths } from '../../../features/profile/profile-route-names'

@Component({
  selector: 'mg-admin-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent {
  mainMenuRoutes: IHeaderRouteInfo[] = [
    {
      label: 'Dashboard',
      icon: 'dashboard',
      path: ApplicationRoutes.dashboard
    },
    {
      label: 'Organizations',
      icon: 'network',
      path: ApplicationRoutes.organizations
    },
    {
      label: 'Shops',
      icon: 'garage',
      path: ApplicationRoutes.shops
    },
    {
      label: 'Users',
      icon: 'users',
      path: ApplicationRoutes.users
    },
    {
      label: 'Market Pricing',
      icon: 'presentation',
      path: ApplicationRoutes.marketPricing
    },
    {
      label: 'Notifications',
      icon: 'bell',
      path: ApplicationRoutes.notifications
    },
    {
      label: 'Global Settings',
      icon: 'settings',
      path: ApplicationRoutes.config
    }
  ]

  profileMenuRoutes: IHeaderRouteInfo[] = [
    {
      label: 'My Profile',
      icon: 'user-circle',
      path: ProfilePaths.editProfile
    }
  ]
}
