<button
  [attr.type]="type"
  [disabled]="disabled || loading"
  [ngClass]="{
    'btn-primary': variant === 'primary',
    'btn-primary-invert': variant === 'primary-invert',
    'btn-accent': variant === 'accent',
    'btn-secondary': variant === 'secondary',
    'btn-error': variant === 'error',
    'btn-ghost': variant === 'ghost',
    'btn-ghost-invert': variant === 'ghost-invert',
    'btn-disabled': this.disabled && variant !== 'ghost',
    'btn-ghost-disabled': this.disabled && variant === 'ghost',
    'size-medium': size === 'medium',
    'size-small': size === 'small',
    'size-small-shrink': size === 'small-shrink'
  }"
  class="btn-base relative {{ extraBtnClass }}"
  [disabled]="loading"
>
  <div
    class="w-full flex justify-center items-center"
    [ngClass]="{
      'flex-row-reverse': iconPosition === 'right',
      'text-transparent': loading && (variant === 'ghost' || variant === 'ghost-invert')
    }"
  >
    <div
      *ngIf="loading"
      class="absolute w-full h-full top-0 left-0 flex items-center justify-center text-white rounded-sm {{
        extraBtnClass
      }}"
      [ngClass]="{
        'btn-primary': variant === 'primary',
        'btn-primary-invert': variant === 'primary-invert',
        'btn-accent': variant === 'accent',
        'btn-secondary !bg-gray-300': variant === 'secondary',
        'btn-error': variant === 'error',
        'btn-ghost': variant === 'ghost',
        'btn-ghost-invert': variant === 'ghost-invert',
        'btn-disabled': this.disabled && variant !== 'ghost',
        'btn-ghost-disabled': this.disabled && variant === 'ghost'
      }"
    >
      <span class="loader"></span>
    </div>
    <div *ngIf="icon">
      <div [ngSwitch]="iconType" class="flex {{ iconContainerClass }}">
        <img *ngSwitchCase="'iconPath'" [src]="icon" />
        <svg-icon *ngSwitchCase="'svgIcon'" [key]="icon" class="{{ iconClass }}"></svg-icon>
      </div>
    </div>
    <div
      [ngClass]="{
        'mr-sm': icon && iconPosition === 'right',
        'ml-sm': icon && iconPosition === 'left',
        'lowercase first-letter:uppercase': lowerCase
      }"
    >
      <ng-content></ng-content>
    </div>
  </div>
</button>
