import { Injectable } from '@angular/core'
import { HttpService } from '@mg-platform/core/core-data-access'
import { DateService } from '@mg-platform/core/core-util'
import { Observable } from 'rxjs'
import { ADMIN_REPORTS_API_URL } from './admin-reports-api-routes'
import { IAdminCalendarRequest, IAdminCalendarResponse } from './interfaces/admin-calendar.interface'
import {
  IAdminShopReportsRequest,
  IAdminShopReportsResponse
} from './interfaces/admin-shop-reports.interface'
import { ICountReportResponse } from './interfaces/count-report.interface'
import {
  IAdminGetPosTypeRequest,
  IAdminPosTypeResponse,
  IAdminUpdatePosTypeRequest,
  IRunShopImportRequest
} from './interfaces/admin-pos-type.interface'

@Injectable({
  providedIn: 'root'
})
export class AdminReportsService {
  constructor(
    private http: HttpService,
    private dateService: DateService
  ) {}

  calendar(payload: IAdminCalendarRequest): Observable<IAdminCalendarResponse> {
    const { fromDate, toDate } = payload
    return this.http.get<IAdminCalendarResponse>(
      `${
        ADMIN_REPORTS_API_URL.calendar
      }?excludeBatch=${!!payload.excludeBatch}&fromDate=${this.dateService.convertToDateString(
        fromDate
      )}&toDate=${this.dateService.convertToDateString(toDate)}`
    )
  }

  getCountReport(excludeBatch: boolean): Observable<ICountReportResponse> {
    return this.http.get<ICountReportResponse>(
      `${ADMIN_REPORTS_API_URL.countReport}?excludeBatch=${!!excludeBatch}`
    )
  }

  getShopWithReports(payload: IAdminShopReportsRequest): Observable<IAdminShopReportsResponse> {
    const { fromDate, toDate } = payload
    const queryParmas: string[] = []

    queryParmas.push(`excludeBatch=${!!payload.excludeBatch}`)
    if (fromDate) {
      queryParmas.push(`fromDate=${this.dateService.momentToDateString(fromDate)}`)
    }

    if (toDate) {
      queryParmas.push(`toDate=${this.dateService.momentToDateString(toDate)}`)
    }

    return this.http.get<IAdminShopReportsResponse>(
      `${ADMIN_REPORTS_API_URL.shopWithReports}?${queryParmas.join('&')}`
    )
  }

  getShopPosType(shopId: string, payload: IAdminGetPosTypeRequest): Observable<IAdminPosTypeResponse> {
    return this.http.post<IAdminPosTypeResponse>(ADMIN_REPORTS_API_URL.shopPosType(shopId), payload)
  }

  updateShopPosType(shopId: string, payload: IAdminUpdatePosTypeRequest) {
    return this.http.put(ADMIN_REPORTS_API_URL.shopPosType(shopId), payload)
  }

  runShopImport(payload: IRunShopImportRequest) {
    return this.http.post(ADMIN_REPORTS_API_URL.runShopImport, {
      ...payload,
      startDate: payload?.startDate ? this.dateService.momentToDateString(payload.startDate) : undefined,
      endDate: payload?.endDate ? this.dateService.momentToDateString(payload.endDate) : undefined
    })
  }
}
