import { CalendarState } from '../enums/calendar-state.enum'

export type CalendarReportType = 'sales' | 'carCount' | 'avgTicket'

export interface ICalendarBenchmarkResponse {
  canEdit: boolean
  day: Date
  outperformeSales?: boolean
  outperformeCarCount?: boolean
  outperformeAvgTicket?: boolean
  dailyReportId?: string
  shop: IShopCalendarBenchmark
  market: IBaseCalendarBenchmark
}

export interface IBaseCalendarBenchmark {
  avgSalesPerBay?: number
  avgCarCount?: number
  avgTicket?: number
}

export interface IShopCalendarBenchmark extends IBaseCalendarBenchmark {
  missingReport?: boolean
  notWorking?: true
  income?: number
  vehicleInvoiced?: number
  isManual: boolean
}

export interface ICalendarMeta {
  showDolarSign: boolean
  state?: CalendarState
  shop?: number
  market?: number
  day: Date
  notWorking: boolean
  income?: number
  vehicleInvoiced?: number
  dailyReportId?: string
  canEdit: boolean
  isManual: boolean
}

export interface ICalendarBenchmarkFilters {
  reportType?: CalendarReportType
  viewDate?: number
}
