<div
  class="w-full flex flex-col-reverse md:flex-row justify-between items-center md:items-center bg-ui-card rounded-base"
  *ngIf="info"
>
  <div class="flex-1 md:p-xl p-lg flex flex-col text-text text-base leading-base">
    <div #titleSlot>
      <ng-content select="[slot=title]"></ng-content>
    </div>
    <span *ngIf="!titleSlot.children.length">{{ info.title }}</span>
    <span *ngIf="info.subTitle">
      {{ info.subTitle }}
    </span>
    <div *ngIf="variant === 'shopNotVerified'" class="mt-lg w-full md:w-max">
      <mg-button class="w-full" size="small" type="button" routerLink="/shops">
        Add more shops
      </mg-button>
    </div>
  </div>
  <div *ngIf="info.imageKey" class="flex justify-end pt-2xl pb-xs md:py-base px-xl md:pr-2xl">
    <mg-dynamic-images class="h-[150px] w-fit" [key]="info.imageKey"></mg-dynamic-images>
  </div>
</div>
