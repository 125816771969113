<div class="cursor-pointer" [ngClass]="{ 'p-sm': !contentSlot.children.length }">
  <div #contentSlot (click)="filterMenuTrigger.openMenu()">
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="!contentSlot.children.length"
    class="min-w-max flex items-center text-base leading-base text-txt"
    [ngClass]="{
      '!text-primary': filter && filter.items && filter.items.length > 0,
      'justify-end pr-sm': rightAlign
    }"
    (click)="
      !onlySort
        ? filterMenuTrigger.openMenu()
        : filterChanged.emit({
            sort: {
              active: filter.key,
              direction: !sort || !sort.direction ? 'desc' : sort.direction === 'desc' ? 'asc' : ''
            }
          })
    "
  >
    <span>
      {{ filter.label }}
    </span>
    <div
      *ngIf="sortDirection"
      class="ml-sm flex text-txt-secondary"
      [ngClass]="{ 'rotate-180': sortDirection === 'desc' }"
    >
      <svg-icon *ngIf="sortDirection" key="arrow-up-full" class="!text-xs"></svg-icon>
    </div>
  </div>
  <div
    [matMenuTriggerFor]="filterMenu"
    #filterMenuTrigger="matMenuTrigger"
    (onMenuOpen)="focusOnInput()"
  ></div>
</div>

<mat-menu
  #filterMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="mg-filter-menu"
  (closed)="onMenuClose()"
>
  <div [id]="filter.key + 'filtertop'"></div>
  <div
    class="relative p-base pb-0 flex flex-col w-[320px] beautyScroll"
    [ngClass]="{
      'md:!w-[450px]': filter.columnType === tableColumnTypes.date,
      '!max-h-[450px]': filter.columnType === tableColumnTypes.checkbox
    }"
    (click)="$event.stopPropagation()"
    (keydown.Tab)="$event.stopPropagation()"
  >
    <div class="flex items-center">
      <div
        class="sort-btn mr-sm"
        [ngClass]="{ 'sort-btn-active': sortDirection === 'asc' }"
        (click)="
          filterChanged.emit({
            sort:
              sort?.active === filter.key && sort?.direction === 'asc'
                ? null
                : { active: filter.key, direction: 'asc' }
          })
        "
      >
        {{ filter.columnType === tableColumnTypes.number ? 'Low to high' : 'Ascending' }}
      </div>
      <div
        class="sort-btn"
        [ngClass]="{ 'sort-btn-active': sortDirection === 'desc' }"
        (click)="
          filterChanged.emit({
            sort:
              sort?.active === filter.key && sort?.direction === 'desc'
                ? null
                : { active: filter.key, direction: 'desc' }
          })
        "
      >
        {{ filter.columnType === tableColumnTypes.number ? 'High to low' : 'Descending' }}
      </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="submit()">
      @if (filter.searchable && filter.columnType === tableColumnTypes.checkbox) {
        <mg-input
          class="block mb-xs"
          controlName="searchText"
          size="small"
          label="Search"
          icon="search"
          [normalInputId]="filter.key + 'input'"
        ></mg-input>
      }
      <div formArrayName="items" *ngFor="let itemForm of $any(itemsControl['controls']); let i = index">
        <ng-container [formGroup]="itemForm">
          @switch (filter.columnType) {
            @case (tableColumnTypes.checkbox) {
              <div class="flex flex-col gap-sm" [ngClass]="{ 'mt-base': !filter.searchable }">
                <div *ngFor="let item of visibleListItems" class="flex items-center cursor-pointer">
                  <mg-checkbox
                    [item]="item"
                    labelClass="!text-base !text-txt"
                    controlName="value"
                  ></mg-checkbox>
                </div>
              </div>
            }

            @case (tableColumnTypes.radio) {
              <div class="mt-base flex flex-col gap-sm">
                <div
                  *ngFor="let item of filter.listItems ?? []"
                  class="flex items-center cursor-pointer"
                >
                  <mg-radio
                    [item]="item"
                    labelClass="!text-base !text-txt"
                    [circleSize]="18"
                    controlName="value"
                  ></mg-radio>
                </div>
              </div>
            }

            @case (tableColumnTypes.date) {
              <mg-range-date-picker
                class="block mt-base"
                [standalone]="true"
                [value]="selectedDateInfo"
                maxDate="Today"
                (valueChanged)="onDateChange(itemForm.get('value'), itemForm.get('extraValue'), $event)"
              ></mg-range-date-picker>
            }

            @default {
              <div class="flex">
                <mg-dropdown-menu
                  *ngIf="filter.columnType === tableColumnTypes.number"
                  class="mr-sm mt-sm"
                  [items]="operators"
                  [selectedItem]="itemForm.get('operator')?.value"
                  (selectedItemChanged)="itemForm.get('operator')?.setValue($event)"
                >
                </mg-dropdown-menu>
                <mg-input
                  class="flex-1"
                  controlName="value"
                  [type]="$any(filter.columnType)"
                  size="small"
                  placeholder="Value"
                  [normalInputId]="filter.key + 'input'"
                ></mg-input>
              </div>
            }
          }
        </ng-container>
      </div>
    </form>

    <div
      class="sticky bg-white left-0 bottom-0 pb-base pt-base flex items-center justify-between"
      [ngClass]="{
        '!pt-xs':
          filter.columnType === tableColumnTypes.number || filter.columnType === tableColumnTypes.text
      }"
    >
      <mg-button
        extraBtnClass="!p-0 !text-txt-secondary"
        variant="ghost"
        size="small"
        (click)="
          filterMenuTrigger.closeMenu();
          filterChanged.emit({
            shouldDelete: true
          })
        "
      >
        Clear
      </mg-button>

      <mg-button extraBtnClass="!p-0" variant="ghost" size="small" (click)="submit()">Filter</mg-button>
    </div>
  </div>
</mat-menu>
