<div class="cursor-pointer p-sm {{ containerClass }}">
  <div
    class="min-w-max flex items-center text-base leading-base text-txt"
    [ngClass]="{
      'justify-end pr-sm': rightAlign
    }"
    (click)="
      !onlySort
        ? sortMenuTrigger.openMenu()
        : emitSortChange({
            active: item.key,
            direction:
              !sortInfo || sortInfo.active !== item.key || !sortInfo.direction
                ? 'desc'
                : sortInfo.direction === 'desc'
                  ? 'asc'
                  : '',
            type: 'value'
          })
    "
  >
    <span [ngClass]="{ 'mr-sm': sortInfo && sortInfo.direction && sortInfo.active === item.key }">
      {{ item.label }}
    </span>
    <div
      *ngIf="sortInfo && sortInfo.direction && sortInfo.active === item.key"
      class="flex text-txt-secondary"
      [ngClass]="{ 'rotate-180': sortInfo.direction === 'asc' }"
    >
      <svg-icon *ngIf="sortInfo.direction" key="arrow-up-full" class="!text-xs"></svg-icon>
    </div>
  </div>
  <div [matMenuTriggerFor]="sortMenu" #sortMenuTrigger="matMenuTrigger"></div>
</div>

<mat-menu
  #sortMenu="matMenu"
  [hasBackdrop]="true"
  backdropClass="mg-menu-backdrop"
  class="mg-filter-menu"
>
  <div
    class="p-base flex flex-col gap-sm"
    (click)="$event.stopPropagation()"
    (keydown.Tab)="$event.stopPropagation()"
  >
    <div class="flex items-center">
      <div class="flex-1 mr-base text-sm leading-sm">Value $</div>
      <div class="flex items-center">
        <div
          class="sort-btn mr-sm"
          [ngClass]="{
            'sort-btn-active':
              sortInfo &&
              sortInfo.active === item.key &&
              sortInfo.type === 'value' &&
              sortInfo.direction === 'asc'
          }"
          (click)="emitSortChange({ active: item.key, direction: 'asc', type: 'value' })"
        >
          Ascending
        </div>
        <div
          class="sort-btn"
          [ngClass]="{
            'sort-btn-active':
              sortInfo &&
              sortInfo.active === item.key &&
              sortInfo.type === 'value' &&
              sortInfo.direction === 'desc'
          }"
          (click)="emitSortChange({ active: item.key, direction: 'desc', type: 'value' })"
        >
          Descending
        </div>
      </div>
    </div>

    <div class="flex items-center">
      <div class="flex-1 mr-base text-sm leading-sm">Percentage</div>
      <div class="flex items-center">
        <div
          class="sort-btn mr-sm"
          [ngClass]="{
            'sort-btn-active':
              sortInfo &&
              sortInfo.active === item.key &&
              sortInfo.type === 'percentage' &&
              sortInfo.direction === 'asc'
          }"
          (click)="emitSortChange({ active: item.key, direction: 'asc', type: 'percentage' })"
        >
          Ascending
        </div>
        <div
          class="sort-btn"
          [ngClass]="{
            'sort-btn-active':
              sortInfo &&
              sortInfo.active === item.key &&
              sortInfo.type === 'percentage' &&
              sortInfo.direction === 'desc'
          }"
          (click)="emitSortChange({ active: item.key, direction: 'desc', type: 'percentage' })"
        >
          Descending
        </div>
      </div>
    </div>
  </div>
</mat-menu>
