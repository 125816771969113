<div class="p-lg md:p-0 flex-1 flex flex-col md:justify-center bg-contrast">
  <mg-add-shop-sections [addNewShop]="addNewShop" [activeSection]="4"></mg-add-shop-sections>

  <div class="title pt-base">Set service prices</div>
  <div class="sub-title pb-lg">Enter the standard prices for your main services.</div>

  <form
    *ngIf="!isLoadingGetThresholds; else loadingTemplate"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="flex-1 md:flex-none flex flex-col"
  >
    <div class="flex-1 md:flex-none flex flex-col">
      <mg-input
        class="w-full"
        controlName="laborRate"
        [useCurrencyMask]="true"
        label="Labor Rate"
        [errorMessages]="[
          {
            type: 'min',
            message: 'Should be more than $' + thresholds.minLaborRate
          },
          {
            type: 'max',
            message: 'Should be less than $' + thresholds.maxLaborRate
          }
        ]"
      ></mg-input>
      <mg-input
        class="w-full"
        controlName="alingment"
        [useCurrencyMask]="true"
        label="Alignment"
        [errorMessages]="[
          {
            type: 'min',
            message: 'Should be more than $' + thresholds.minAlignment
          },
          {
            type: 'max',
            message: 'Should be less than $' + thresholds.maxAlignment
          }
        ]"
      ></mg-input>
      <mg-input
        class="w-full"
        controlName="oilChange"
        [useCurrencyMask]="true"
        label="Oil Change"
        [errorMessages]="[
          {
            type: 'min',
            message: 'Should be more than $' + thresholds.minOilChange
          },
          {
            type: 'max',
            message: 'Should be less than $' + thresholds.maxOilChange
          }
        ]"
      ></mg-input>
    </div>

    <div *ngIf="addNewShop" class="mt-lg w-full hidden md:flex flex-row items-center justify-end">
      <mg-button
        class="mr-2xl"
        type="button"
        variant="ghost"
        size="small-shrink"
        (click)="completed.emit()"
      >
        Skip
      </mg-button>
      <mg-button
        type="submit"
        variant="ghost"
        size="small-shrink"
        [loading]="isProcessing"
        [disabled]="form.invalid"
      >
        Done
      </mg-button>
    </div>

    <div class="w-full mt-lg flex flex-col" [ngClass]="{ 'md:hidden': addNewShop }">
      <mg-button
        class="w-full"
        type="submit"
        size="small"
        [loading]="isProcessing"
        [disabled]="form.invalid"
      >
        Done
      </mg-button>
      <mg-button
        class="mt-base md:pb-base w-full"
        type="button"
        size="small"
        variant="ghost"
        (click)="completed.emit()"
      >
        Skip
      </mg-button>
    </div>
  </form>
</div>

<ng-template #loadingTemplate>
  <div class="mt-2xl flex justify-center">
    <mat-progress-spinner [diameter]="80" mode="indeterminate"></mat-progress-spinner>
  </div>
</ng-template>
