import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { UsersService } from '../../users.service'
import { GetAllAdminUsers, GetOneAdminUser, UpdateAdminUser } from './admin-users.actions'
import { AdminUsersStateModel } from './admin-users.model'

@State<AdminUsersStateModel>({
  name: 'adminUsers'
})
@Injectable()
export class AdminUsersState {
  constructor(private usersService: UsersService) {}

  @Selector()
  static user(state: AdminUsersStateModel) {
    return state.user
  }

  @Selector()
  static allUsers(state: AdminUsersStateModel) {
    return state.allUsers
  }

  @Action(GetAllAdminUsers)
  getAllAdminUsers(
    ctx: StateContext<AdminUsersStateModel>,
    { info, organizationId, shopId }: GetAllAdminUsers
  ) {
    return this.usersService.getAllAdminUsers(info, organizationId, shopId).pipe(
      tap((res) => {
        ctx.patchState({
          allUsers: res
        })
      })
    )
  }

  @Action(GetOneAdminUser)
  getOneAdminUser(ctx: StateContext<AdminUsersStateModel>, { userId }: GetOneAdminUser) {
    return this.usersService.getOneAdminUser(userId).pipe(
      tap((res) => {
        ctx.patchState({ user: res })
      })
    )
  }

  @Action(UpdateAdminUser)
  updateAdminUser(ctx: StateContext<AdminUsersStateModel>, { userId, payload }: UpdateAdminUser) {
    return this.usersService.updateAdminUser(userId, payload)
  }
}
