<div
  class="w-full bg-ui-bg px-sm py-base lg:hidden flex items-center justify-between"
  style="z-index: 1000"
>
  <div class="grow-0 shrink-0 ml-base cursor-pointer" routerLink="/dashboard">
    <img src="assets/images/icons/logo-full.svg" class="h-[40px]" alt="Logo" />
  </div>

  <div class="flex items-center">
    <div class="p-sm flex cursor-pointer mr-base text-txt" (click)="openMenuChangeModeDialog()">
      <svg-icon key="palette" class="!text-lg"></svg-icon>
    </div>

    <div
      class="p-sm flex cursor-pointer mr-base text-txt"
      (click)="openMenuItemsDialog(profileMenuRoutes, true)"
    >
      <svg-icon key="user" class="!text-lg"></svg-icon>
    </div>

    <div
      class="p-sm flex pt-sm cursor-pointer text-txt"
      (click)="openMenuItemsDialog(mainMenuRoutes, false)"
    >
      <svg-icon key="menu" class="!text-lg"></svg-icon>
    </div>
  </div>
</div>
