import { IChartRange } from '@mg-platform/core/core-data-access'
import { MarketType } from '../enums/market-type.enum'

export interface ITranslatedTrend {
  shop: number
  market: number
  weekNumber: number
  period: string
}

export interface IGraphData {
  data: number
  trend: number
  weekNumber: number
  period: string
}

export interface ITrendGrapgh {
  header: {
    numberOfWeeks: number
    outperformingMarket: boolean
    shopTrendPercentage: number
    marketTrendPercentage: number
    hasTrends: boolean
  }
  translatedTrend: ITranslatedTrend[]
  shop: IGraphData[]
  market: IGraphData[]
}

export interface ITrendItem {
  graphs: ITrendGrapgh[]
  range: IChartRange
}

export interface ITrendResponse {
  avgSales: ITrendItem
  avgCarCount: ITrendItem
  avgTicket: ITrendItem
  shopId?: string
  marketType?: MarketType
}

export interface ITrendFilters {
  avgSalesPerBay?: boolean
  avgCarCount?: boolean
  avgTicket?: boolean
}
