<div
  mgEnterViewport
  (visibilityChange)="!isVisible ? (isVisible = $event) : undefined; drawRing()"
  class="relative flex items-center justify-center"
  [style.height.px]="size"
  [style.width.px]="size"
>
  <div class="ring-chart-animatable-container absolute left-0 top-0 w-full h-full">
    <svg
      id="svgContainer{{ randomId }}"
      viewBox="-2 -2 124 124"
      [attr.width]="size"
      [attr.height]="size"
    >
      <path
        class="ring-progress-meter"
        id="path{{ randomId }}"
        d="M60,6A54,54 0 0 1 60,114A54,54 0 0 1 60,6z"
        stroke-width="12"
      />
      <g id="ringValueContainer{{ randomId }}"></g>
    </svg>
  </div>

  <ng-content></ng-content>
</div>
