export interface ICompTrendsBaseHeader {
  label?: string
  trendPercentage: number
  numberOfWeeks: number
  hasTrends: boolean
}

export interface ICompTrendsBaseData {
  data: number
  trend: number
  weekNumber: number
  period: string
}

export interface ICompTrendsBaseItem {
  header: ICompTrendsBaseHeader
  data: ICompTrendsBaseData[]
}
