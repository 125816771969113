export enum MarketType {
  local = 'local',
  organization = 'organization',
  state = 'state',
  regional = 'regional',
  national = 'national',
  custom = 'custom',
  shop = 'shop',
  group = 'group'
}

export const MarketTypeToIndex: { [key in MarketType]: number } = {
  local: 5,
  organization: 0,
  state: 2,
  regional: 3,
  national: 4,
  custom: 6,
  shop: 0,
  group: 1
}
