import { MarketType } from '../enums/market-type.enum'
import { TrendIntervalType } from '../enums/trend-interval-type.enum'

export interface IAverageTrendData {
  startTotal: number
  endTotal: number
  difference: number
  formattedStartTotal: string
  formattedEndTotal: string
  formattedDifference: string
}

export interface IAverageTrendSummary {
  id: string
  displayText: string
  marketType: MarketType
  countryId: string
  trendIntervalType: TrendIntervalType
  sales: IAverageTrendData
  cars: IAverageTrendData
  averageTicket: IAverageTrendData
}

export interface IAverageTrendDataExtended extends IAverageTrendData {
  id: string
  displayText: string
  marketType: MarketType
  countryId: string
}

export interface IAverageTrendSummaryExtended {
  sales: IAverageTrendDataExtended[]
  cars: IAverageTrendDataExtended[]
  averageTicket: IAverageTrendDataExtended[]
}

export interface ITrendIntervalLabels {
  interval: TrendIntervalType
  initialPeriod: string
  finalPeriod: string
}

export interface IAverageTrendResponse {
  averageTrendSummaries: IAverageTrendSummary[]
  trendIntervalLabels: ITrendIntervalLabels[]
  trendsCustomInterval: number
}

export interface IAverageTrendResponseExtended {
  fourWeeks: IAverageTrendSummaryExtended
  eightWeeks: IAverageTrendSummaryExtended
  customWeeks: IAverageTrendSummaryExtended
  trendsCustomInterval: number
}
