import {
  ICompTrendsBaseData,
  ICompTrendsBaseHeader,
  ICompTrendsBaseItem
} from '@mg-platform/core/core-data-access'
import { MarketType } from '../enums/market-type.enum'

export interface ISelectedShops {
  shopsId: string[]
  nationalSelected: boolean
  myOrganizationSelected: boolean
}

export interface ICompTrendsReportRequest {
  includeNational: boolean
  includeMyOrganization: boolean
  shopIds?: string[]
}

export interface ICompTrendsReportHeader extends ICompTrendsBaseHeader {
  marketType: MarketType
  organizationId?: string
  organizationName?: string
  shopId?: string
  shopName?: string
  stateId?: string
  stateName?: string
  regionId?: string
  regionName?: string
}

export interface ICompTrendsReportItem extends ICompTrendsBaseItem {
  header: ICompTrendsReportHeader
  data: ICompTrendsBaseData[]
}

export interface ICompTrendsReportDetail {
  graphs4Weeks?: ICompTrendsReportItem[]
  graphs8Weeks?: ICompTrendsReportItem[]
  graphs12Weeks?: ICompTrendsReportItem[]
}

export interface ICompTrendsReportResponse {
  avgSales: ICompTrendsReportDetail
  avgCarCount: ICompTrendsReportDetail
  avgTicket: ICompTrendsReportDetail
}
