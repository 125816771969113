import { Injectable } from '@angular/core'
import { Sort, SortDirection } from '@angular/material/sort'
import { orderBy } from 'lodash'
import { MarketTypeSortKey } from './card-sort.component'
import { MarketType, MarketTypeToIndex } from '@mg-platform/reports/reports-data-access'

@Injectable({
  providedIn: 'root'
})
export class CardSortService {
  sort(items: any[], sortInfo: Sort, title?: string): { items: any[]; sortInfo: Sort } {
    if (!title) {
      return { items: [...items], sortInfo }
    }

    if (sortInfo?.active !== title) {
      sortInfo = {
        active: title,
        direction: 'desc'
      }
    } else {
      let direction: SortDirection = ''
      if (sortInfo.direction === 'desc') {
        direction = 'asc'
      } else if (sortInfo.direction === 'asc') {
        direction = ''
      } else {
        direction = 'desc'
      }
      sortInfo = { active: title, direction }
    }

    if (!sortInfo.direction || (sortInfo.direction as string) === '') {
      return { items: [...items], sortInfo }
    }

    return {
      items: orderBy(
        items,
        sortInfo.active === MarketTypeSortKey
          ? (a) => MarketTypeToIndex[a[MarketTypeSortKey] as MarketType]
          : sortInfo.active,
        sortInfo.direction === 'asc' ? 'asc' : 'desc'
      ),
      sortInfo
    }
  }
}
