import { IAdminMarketPricingResponse } from '../../interfaces/admin-market-pricings.interface'
import {
  IMarketPricingResponse,
  IMarketPricingThresholds
} from '../../interfaces/market-pricings.interface'

export interface MarketPricingReportsStateModel {
  marketPricing: IMarketPricingResponse
  marketPricingChanged: boolean
  thresholds: IMarketPricingThresholds
  allMarketPricingsResponse: IAdminMarketPricingResponse
}
