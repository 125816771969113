import { ColorType, ICompareWithSelection } from '@mg-platform/core/core-data-access'

export interface IWeeklyReportRequest extends Partial<ICompareWithSelection> {
  weekNumber: number
}

export interface IMy4WeekShopDetails {
  period: string
  weekNumber: number
  value: number
  formattedValue: string
}

export interface IMy4WeekShop {
  sales: IMy4WeekShopDetails[]
  cars: IMy4WeekShopDetails[]
  averageTicket: IMy4WeekShopDetails[]
}

export interface IWeeklySalesReportDetails {
  formattedLastWeek: string
  formattedMonthToDate: string
  formattedYearToDate: string
  formattedRollingAverage: string
  rollingAveragePercentage: number
}

export interface IWeeklySalesReport {
  sales: IWeeklySalesReportDetails
  cars: IWeeklySalesReportDetails
  averageTicket: IWeeklySalesReportDetails
}

export interface IShopGoalItem {
  actual: number
  formattedActual: string
  goal: number
  formattedGoal: string
  actualWorkingDays: number
  formattedActualPerDay: string
  actualPercentage: number
  goalPercentage: number
  line1: string
  line2: string
  pace: string
}

export interface IShopGoalForecastItem {
  forecast: number
  formattedForecast: string
  forecastWorkingDays: number
  forecastPercentage: number
  formattedPercent: string
  colorType: ColorType
}

export interface IMonthlyGoal {
  title: string
  salesGoal: IShopGoalItem
  carCountGoal: IShopGoalItem
  salesForecast: IShopGoalForecastItem
  carCountForecast: IShopGoalForecastItem
  shopIds: string[]
  shopsCount: number
}

export interface IWeeklyReportSales {
  weeklySalesSummary?: IWeeklySalesReport
}

export interface IWeeklyReportMonthlyGoals {
  monthlyGoal?: IMonthlyGoal
}

export interface IWeeklyReportPreviousFourthWeeks {
  my4WeekShop?: IMy4WeekShop
}
