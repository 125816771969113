import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext } from '@ngxs/store'
import { tap } from 'rxjs/operators'
import { NotificationsService } from '../../notifications.service'
import { GetAllNotifications } from './notifications.actions'
import { NotificationsStateModel } from './notifications.model'

@State<NotificationsStateModel>({
  name: 'notifications'
})
@Injectable()
export class NotificationsState {
  constructor(private notificationsService: NotificationsService) {}

  @Selector()
  static allNotificationsResponse(state: NotificationsStateModel) {
    return state.allNotificationsResponse
  }

  @Action(GetAllNotifications)
  getAllNotifications(ctx: StateContext<NotificationsStateModel>, { info }: GetAllNotifications) {
    return this.notificationsService.getAllNotifications(info).pipe(
      tap((res) => {
        ctx.patchState({
          allNotificationsResponse: res
        })
      })
    )
  }
}
