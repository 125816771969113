<div class="relative">
  <mg-tippy-tooltip *ngIf="groupId" [clickable]="true">
    <div>
      <div *ngFor="let item of groupShopList" class="mt-2xs first:mt-0 flex items-center">
        <div class="mr-sm w-sm h-sm rounded-full bg-graph-5"></div>
        <div class="text-sm leading-sm text-txt">{{ item.displayName }}</div>
      </div>
    </div>
  </mg-tippy-tooltip>

  <div
    class="text-center border rounded-full {{ extraClass }}"
    [ngClass]="{
      '!rounded-sm': groupId,
      'm-size': size === 'm',
      's-size': size === 's',
      'bg-ui-selected border-ui-selected text-white': selected,
      'border-gray-100 text-txt': !selected,
      'hover:bg-ui-hover': !selected && hasHoverEffect,
      'cursor-pointer': hasHoverEffect
    }"
  >
    {{ label }}
  </div>
</div>
