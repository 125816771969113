import { Injectable } from '@angular/core'
import sign from 'jwt-encode'
import { FiltersStateModel } from '../stores/filters/filters.model'
import { environment } from '@mg-platform/core/core-util'
import { IUrlFilterItem } from '../interfaces/url-filters.interface'

@Injectable({
  providedIn: 'root'
})
export class SignUrlService {
  generateFilters(filters: Partial<FiltersStateModel>): IUrlFilterItem {
    return {
      filters: sign({ ...filters }, environment.jwtSecret)
    }
  }
}
