// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'dev',
  apiUrl: '/api/v2.0',
  websiteUrl: 'https://my.marketgenie.me',
  adminUrl: 'https://admin.marketgenie.me',
  publicUrl: 'https://marketgenie.me',
  pageSize: 100,
  googlePlacesKey: 'AIzaSyDdkA_2NcJKVMk1PS1dpxFxI3u0hV0uOpo',
  reCaptchaKey: '6LeAbtUgAAAAANfM_YkH54YkNuKGtrlHBRGnNKsB',
  appInsights: {
    instrumentationKey: '4e86945c-0dfb-40e3-a288-bc90501e646f'
  },
  googleTagManagerToken: 'GTM-M55PDG7P',
  jwtSecret: 'simple_jwt_secret'
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
