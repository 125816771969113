import { environment } from '@mg-platform/core/core-util'

export const USERS_API_URL = {
  // Users
  base: (userId: string) => `${environment.apiUrl}/users/${userId}`,
  login: `${environment.apiUrl}/users/login`,
  refreshToken: `${environment.apiUrl}/users/refresh`,
  loginAutovitals: `${environment.apiUrl}/users/login-autovitals`,
  register: `${environment.apiUrl}/users/register`,
  logout: `${environment.apiUrl}/users/logout`,
  me: `${environment.apiUrl}/users/me`,
  requestPasswordReset: `${environment.apiUrl}/users/requestpasswordreset`,
  resetPassword: `${environment.apiUrl}/users/resetpassword`,
  updateProfile: `${environment.apiUrl}/users`,
  updatePassword: `${environment.apiUrl}/users/password`,
  preferences: `${environment.apiUrl}/users/preferences`,

  // User Invitation
  sendInvitation: `${environment.apiUrl}/invitations/inviteuser`,
  getInvitationInfo: (token: string) => `${environment.apiUrl}/invitations?token=${token}`,
  answerInvitation: `${environment.apiUrl}/invitations`,
  checkInvitedUser: (token: string) => `${environment.apiUrl}/invitations/checkuser?token=${token}`,
  deleteInvitation: (id: string) => `${environment.apiUrl}/invitations/${id}`,

  // Admin Users
  getAdminUser: (userId: string) => `${environment.apiUrl}/admin/users/${userId}`,
  getAllAdminUsers: `${environment.apiUrl}/admin/filters/users`,
  exportAllAdminUsers: `${environment.apiUrl}/admin/filters/users/export`
}
