import { IAverageTrendResponse } from './average-trends.interface'
import { ICompareResponse } from './compare-report.interface'
import { IMonthlyGoal, IMy4WeekShop, IWeeklySalesReport } from './weekly-report.interface'

export interface IPublicWeeklyReportResponse {
  shopName: string
  subtitle: string
  summary: {
    my4WeekShop?: IMy4WeekShop
    weeklySalesSummary?: IWeeklySalesReport
    comparativeSales?: ICompareResponse
    monthlyGoal?: IMonthlyGoal
    trendSummary?: IAverageTrendResponse
  }
}

export interface IPublicWeeklyReportSummary {
  shopName: string
  subtitle: string
  my4WeekShop?: IMy4WeekShop
  weeklySalesSummary?: IWeeklySalesReport
  comparativeSales?: ICompareResponse
  monthlyGoal?: IMonthlyGoal
  trendSummary?: IAverageTrendResponse
}
