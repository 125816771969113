import { IPaginationFilteredResponse } from '@mg-platform/core/core-feature'

export interface IAdminMarketPricingItem {
  shopId: string
  shop: string
  organization: string
  laborRate: number
  alignment: number
  oilChange: number
}

export interface IAdminMarketPricingResponse extends IPaginationFilteredResponse {
  marketPrices: IAdminMarketPricingItem[]
}
