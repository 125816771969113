<div
  class="flex text-right justify-end items-center"
  [ngClass]="{
    'text-success': isSuccess,
    'text-error': !isSuccess
  }"
>
  <svg-icon [key]="isSuccess ? 'arrow-north-east' : 'arrow-south-east'" class="!text-2xs"></svg-icon>
  <span class="pl-sm text-base leading-base">
    <ng-content></ng-content>
  </span>
</div>
