<div class="relative flex items-center justify-center" [style.height.px]="size" [style.width.px]="size">
  <div class="absolute left-0 top-0 {{ info.colorClass }}">
    <svg
      *ngIf="info.value < 100"
      [attr.width]="size"
      [attr.height]="size"
      style="transform: rotate(-90deg)"
    >
      <circle
        [attr.r]="radius"
        cx="50%"
        cy="50%"
        stroke-linecap="round"
        fill="transparent"
        stroke="currentColor"
        [attr.stroke-width]="ringWidth + 'px'"
        [attr.stroke-dasharray]="info.dasharray"
        [attr.stroke-dashoffset]="info.dashoffset"
      ></circle>
    </svg>

    <svg
      *ngIf="info.value >= 100"
      xmlns="http://www.w3.org/2000/svg"
      [attr.width]="size"
      [attr.height]="size"
      viewBox="-6 -6 212 212"
      fill="none"
      [ngStyle]="{ transform: 'rotate(' + rotateDeg + 'deg)' }"
    >
      <defs>
        <linearGradient [attr.id]="'spinner-secondHalf' + randomId">
          <stop offset="0%" stop-opacity="0.7" stop-color="currentColor" />
          <stop offset="50%" stop-opacity="1" stop-color="currentColor" />
          <stop offset="100%" stop-opacity="1" stop-color="currentColor" />
        </linearGradient>
        <linearGradient [attr.id]="'spinner-firstHalf' + randomId">
          <stop offset="0%" stop-opacity="1" stop-color="currentColor" />
          <stop offset="100%" stop-opacity="1" stop-color="currentColor" />
        </linearGradient>
      </defs>

      <g stroke-width="20">
        <path
          [attr.stroke]="'url(#spinner-secondHalf' + randomId + ')'"
          stroke-linecap="round"
          d="M 4 100 A 96 96 0 0 1 196 100"
        />
        <path
          [attr.stroke]="'url(#spinner-firstHalf' + randomId + ')'"
          d="M 196 100 A 96 96 0 0 1 4 100"
        />

        <!-- highlight-start -->
        <!-- 1deg extra path to have the round end cap -->
        <path stroke="currentColor" stroke-linecap="round" d="M 4 100 A 96 96 0 0 1 4 98" />
        <!-- highlight-end -->
      </g>
    </svg>
  </div>

  <ng-content></ng-content>
</div>
