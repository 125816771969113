import { MarketType } from '../enums/market-type.enum'
import { ShopTagSize } from '../enums/shop-tag-size.enum'

export interface IGrossprofitSummary {
  grossProfit: number
  sales: number
  cost: number
  grossProfitFormatted: string
  salesFormatted: string
  costFormatted: string
}

export interface IGrossprofitData {
  day: string
  grossProfit: number
  sales: number
  cost: number
  grossProfitFormatted: string
  salesFormatted: string
  costFormatted: string
}

export interface IGrossprofitSummaryItem {
  firstReport: string
  lastReport: string
  summary: IGrossprofitSummary
  grossProfitData: IGrossprofitData[]
  id: string
  displayText: string
  marketType: MarketType
}

export interface IGrossProfitTagSizeItem {
  tagSize: ShopTagSize
  value: string
}

export interface IGrossProfitTagSizeCountry {
  countryId: string
  nationalValue: string
  items: IGrossProfitTagSizeItem[]
}

export interface IGrossprofitReportResponse {
  grossProfitSummaries: IGrossprofitSummaryItem[]
  intervalLabel: string
}

export interface IGrossProfitByTagSizeResponse {
  countries: IGrossProfitTagSizeCountry[]
}

export interface IGrossProfitByTagSizeInfo {
  nationalValue: string
  [ShopTagSize.LessThanMillion]?: string
  [ShopTagSize.BetweenMillionAndTwoMillion]?: string
  [ShopTagSize.OverTwoMillion]?: string
}
