import { Component, Input, OnInit } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'
import { DynamicImageKey } from '../dynamic-images/dynamic-images.component'

@UntilDestroy()
@Component({
  selector: 'mg-no-report-banner',
  templateUrl: './no-report-banner.component.html',
  styleUrls: ['./no-report-banner.component.scss']
})
export class NoReportBannerComponent implements OnInit {
  @Input() variant: 'shopNotVerified' | 'notEnoughData' | 'wrongDataRange'

  @Input() info?: { title?: string; subTitle?: string; imageKey?: DynamicImageKey }

  ngOnInit(): void {
    switch (this.variant) {
      case 'notEnoughData':
        this.info = {
          title: this.info?.title ?? 'There is not enough data to show this chart.',
          subTitle: this.info?.subTitle ?? 'Please use another report for now',
          imageKey: this.info?.imageKey ?? 'no-report'
        }
        break

      case 'shopNotVerified':
        this.info = {
          title: this.info?.title ?? 'To ensure data accuracy, every shop on Market Genie is verified.',
          subTitle: this.info?.subTitle ?? 'This process may take up to 48 hours.',
          imageKey: this.info?.imageKey ?? 'not-verified'
        }
        break

      case 'wrongDataRange':
        this.info = {
          title: this.info?.title ?? 'There is no data for this period.',
          subTitle: this.info?.subTitle ?? 'Try another period.',
          imageKey: this.info?.imageKey ?? 'no-report'
        }
        break
    }
  }
}
