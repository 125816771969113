import { NotificationChannel } from '../enums/notification-channel.enum'
import { NotificationType } from '../enums/notification-type.enum'
import { IPaginationFilteredResponse } from '@mg-platform/core/core-feature'

export interface INotificationItem {
  type: NotificationType
  channel: NotificationChannel
  user: string
  shop: string
  title: string
  body: string
  created: Date
}

export interface IAllNotificationsResponse extends IPaginationFilteredResponse {
  notifications: INotificationItem[]
}
