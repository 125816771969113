import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

export interface ISectionAction {
  title: string
  link?: string
  icon?: string
  showTitleOnMobile?: boolean
}

@UntilDestroy()
@Component({
  selector: 'mg-section-title',
  templateUrl: './section-title.component.html',
  styleUrls: ['./section-title.component.scss']
})
export class SectionTitleComponent {
  @Input() title?: string
  @Input() subTitle?: string
  @Input() action?: ISectionAction

  @Output() actionClicked = new EventEmitter()
}
