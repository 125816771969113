<div class="w-full flex h-[100dvh] overflow-hidden">
  <div class="flex-1 bg-primary h-full hidden md:block">
    <div class="logo-section h-full relative flex flex-col justify-end">
      <img class="absolute left-2xl top-8" src="assets/images/icons/big-logo.svg" />

      <div class="text-white">
        <div class="text-2xl leading-2xl mb-2xl">Market Genie</div>

        <div class="text-2xl leading-2xl">
          Providing you with real-time market benchmarks, trends and insights
        </div>

        <ng-container *ngIf="referralType === referralTypes.protractor">
          <div class="mt-2xl mb-base font-semibold text-xl leading-xl text-white">
            In partnership with:
          </div>
          <img src="assets/images/protractor/new-logo.png" width="228px" alt="Protractor Logo" />
        </ng-container>
      </div>
    </div>
  </div>
  <div class="flex-1 h-full overflow-auto">
    <div class="content-container mx-auto h-full py-2xl px-lg">
      <div class="mb-lg flex md:hidden justify-center items-center">
        <img class="h-2xl" src="assets/images/icons/logo.svg" />
      </div>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
