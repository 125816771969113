import { IAvailableWeek } from '../../interfaces/available-weeks.interface'
import { IAverageTrendResponse } from '../../interfaces/average-trends.interface'
import { ICompareResponse } from '../../interfaces/compare-report.interface'
import { IPublicWeeklyReportResponse } from '../../interfaces/public-weekly-report.interface'
import {
  IWeeklyReportMonthlyGoals,
  IWeeklyReportPreviousFourthWeeks,
  IWeeklyReportSales
} from '../../interfaces/weekly-report.interface'

export interface WeeklyReportsStateModel {
  sales: IWeeklyReportSales
  compSales: ICompareResponse
  monthlyGoals: IWeeklyReportMonthlyGoals
  averageTrends: IAverageTrendResponse
  previousFourthWeeks: IWeeklyReportPreviousFourthWeeks
  availableWeeks: IAvailableWeek[]
  publicReports: IPublicWeeklyReportResponse
}
