import { ReferralType } from '../../enums/referral-type.enum'
import { IUserPreferences } from '../../interfaces/user-preferences.interface'
import { IUser } from '../../interfaces/user.interface'

export interface UserAccountStateModel {
  user: IUser
  isAuthenticated: boolean
  userPreferences: IUserPreferences
  returnUrl: string
  referralType: ReferralType
}
