import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import {
  CalendarMaxDate,
  EndOFLastWeekString,
  PeriodType,
  PeriodTypeButtonsInfo,
  PeriodTypeToLabel,
  TodayString,
  YesterdayString
} from '@mg-platform/core/core-data-access'
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { IRangeDateInfo } from '../../interfaces/range-date.interface'
import { ISelectItem } from '@mg-platform/core/core-ui'
import moment, { Moment, isMoment } from 'moment'
import { DateRange, MatCalendar } from '@angular/material/datepicker'

@UntilDestroy()
@Component({
  selector: 'mg-range-date-picker',
  templateUrl: './range-date-picker.component.html',
  styleUrls: ['./range-date-picker.component.scss']
})
export class RangeDatePickerComponent implements OnInit, OnChanges {
  @ViewChild(MatCalendar) calendar: MatCalendar<Date>

  @Input() standalone = false
  @Input() value?: IRangeDateInfo
  @Input() maxDate?: CalendarMaxDate
  @Input() minDate?: string
  @Input() hiddenPeriods: PeriodType[] = []
  @Input() hideAllPeriods: boolean

  @Output() valueChanged = new EventEmitter<IRangeDateInfo>()
  @Output() canceld = new EventEmitter()

  calendarMaxDate?: string
  rangeForm: UntypedFormGroup
  periodType = PeriodType
  periodTypeButtonsInfo: ISelectItem[] = []
  periodTypeToLabel = PeriodTypeToLabel

  dateFormat = 'MMM DD, YYYY'
  dateSecondFormat = 'MMM D, YYYY'
  selectedDateRange: DateRange<Date>
  fromControl: AbstractControl | null
  fromTextControl: AbstractControl | null
  toControl: AbstractControl | null
  toTextControl: AbstractControl | null

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    switch (this.maxDate) {
      case 'Today':
        this.calendarMaxDate = TodayString()
        break

      case 'Yesterday':
        this.calendarMaxDate = YesterdayString()
        break

      case 'EndOFLastWeek':
        this.calendarMaxDate = EndOFLastWeekString()
        break

      default:
        this.calendarMaxDate = undefined
        break
    }
    this.periodTypeButtonsInfo = PeriodTypeButtonsInfo().filter(
      (el) => !this.hiddenPeriods.includes(el.value) && el.value !== PeriodType.Custom
    )

    if (this.value && this.value.periodType !== PeriodType.Custom) {
      this.value.rangeDate = PeriodTypeButtonsInfo().find(
        (el) => el.value === this.value?.periodType
      )?.extraInfo
    }

    const { fromInRange, toInRange } = this.getInRangeDates()

    this.rangeForm = this.fb.group({
      from: [fromInRange],
      fromText: [this.transformDate(fromInRange)],
      to: [toInRange],
      toText: [this.transformDate(toInRange)]
    })

    this.fromControl = this.rangeForm.get('from')
    this.fromTextControl = this.rangeForm.get('fromText')
    this.toControl = this.rangeForm.get('to')
    this.toTextControl = this.rangeForm.get('toText')

    this.fromControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.fromTextControl?.setValue(this.transformDate(value), {
          emitEvent: false
        })
      }
    })

    this.toControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.toTextControl?.setValue(this.transformDate(value), {
          emitEvent: false
        })
      }
    })

    this.fromTextControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      const momentDate = moment(value, [this.dateFormat, this.dateSecondFormat], true)
      if (
        value &&
        momentDate.isValid() &&
        !momentDate.isAfter(this.calendarMaxDate) &&
        (!this.toControl?.value ||
          momentDate.isSame(this.toControl?.value, 'days') ||
          !momentDate.isAfter(this.toControl?.value))
      ) {
        this.fromControl?.setValue(momentDate.utcOffset(0, true), { emitEvent: false })
        if (this.value) {
          this.value.periodType = PeriodType.Custom
        } else {
          this.value = {
            periodType: PeriodType.Custom
          }
        }
        this.setCalendarDate()
        if (this.standalone) {
          this.fireValueChangedEvent()
        }
      }
    })

    this.toTextControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      const momentDate = moment(value, [this.dateFormat, this.dateSecondFormat], true)
      if (
        value &&
        momentDate.isValid() &&
        !momentDate.isAfter(this.calendarMaxDate) &&
        (!this.fromControl?.value ||
          momentDate.isSame(this.fromControl?.value, 'days') ||
          !momentDate.isBefore(this.fromControl?.value))
      ) {
        this.toControl?.setValue(momentDate.utcOffset(0, true), { emitEvent: false })
        if (this.value) {
          this.value.periodType = PeriodType.Custom
        } else {
          this.value = {
            periodType: PeriodType.Custom
          }
        }
        this.setCalendarDate()

        if (this.standalone) {
          this.fireValueChangedEvent()
        }
      }
    })

    if (this.standalone) {
      setTimeout(() => {
        this.fireValueChangedEvent()
      })
    }
  }

  ngOnChanges(): void {
    if (!this.value?.rangeDate) {
      if (this.value) {
        this.value.rangeDate = {
          from: this.calendarMaxDate,
          to: this.calendarMaxDate
        }
      } else {
        this.value = {
          rangeDate: {
            from: this.calendarMaxDate,
            to: this.calendarMaxDate
          }
        }
      }
    }

    const { fromInRange, toInRange } = this.getInRangeDates()

    this.rangeForm?.patchValue(
      {
        from: fromInRange,
        fromText: this.transformDate(fromInRange),
        to: toInRange,
        toText: this.transformDate(toInRange)
      },
      { emitEvent: false }
    )
    this.setCalendarDate()
  }

  fireValueChangedEvent() {
    const from = this.fromControl?.value
    let to = this.toControl?.value

    if (!to) {
      to = from
      this.toControl?.setValue(to)
    }

    this.valueChanged.emit({
      rangeDate: {
        from: isMoment(from) ? from.utc().format() : from,
        to: isMoment(to) ? to.utc().format() : to
      },
      periodType: this.value?.periodType
    })
  }

  setDateByPeriodType(item: ISelectItem) {
    if (this.value) {
      this.value.periodType = item.value
    } else {
      this.value = {
        periodType: item.value
      }
    }

    this.rangeForm.patchValue({
      from: item.extraInfo.from.isAfter(this.calendarMaxDate)
        ? moment(this.calendarMaxDate).utc()
        : item.extraInfo.from,
      to: item.extraInfo.to.isAfter(this.calendarMaxDate)
        ? moment(this.calendarMaxDate).utc()
        : item.extraInfo.to
    })
    this.setCalendarDate()

    if (this.standalone) {
      this.fireValueChangedEvent()
    }
  }

  transformDate(value?: Moment) {
    return `${value?.format(this.dateFormat)}`
  }

  onSelectedDateChange(date: Date): void {
    let isEndData = false
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date >= this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(this.selectedDateRange.start, date)
      isEndData = true
    } else {
      this.selectedDateRange = new DateRange(date, null)
    }

    this.fromControl?.setValue(this.selectedDateRange.start)
    this.toControl?.setValue(this.selectedDateRange.end)
    if (this.value) {
      this.value.periodType = PeriodType.Custom
    } else {
      this.value = {
        periodType: PeriodType.Custom
      }
    }
    if (isEndData && this.standalone) {
      this.fireValueChangedEvent()
    }
  }

  public setCalendarDate() {
    setTimeout(() => {
      this.selectedDateRange = new DateRange(this.fromControl?.value, this.toControl?.value)
      if (this.calendar) {
        this.calendar.activeDate = this.fromControl?.value
      }
    })
  }

  getInRangeDates() {
    const from = moment(this.value?.rangeDate?.from).utc()
    const to = moment(this.value?.rangeDate?.to).utc()
    const fromInRange = from.isAfter(this.calendarMaxDate) ? moment(this.calendarMaxDate).utc() : from
    const toInRange = to.isAfter(this.calendarMaxDate) ? moment(this.calendarMaxDate).utc() : to

    return { fromInRange, toInRange }
  }
}
