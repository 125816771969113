import { Component, Input } from '@angular/core'
import { UntilDestroy } from '@ngneat/until-destroy'

@UntilDestroy()
@Component({
  selector: 'mg-percentage-info',
  templateUrl: './percentage-info.component.html',
  styleUrls: ['./percentage-info.component.scss']
})
export class PercentageInfoComponent {
  @Input() isSuccess = true
}
