import { CountryCode } from '../enums/country-code.enum'

export interface IState {
  stateId: string
  stateName: string
  abbreviation: string
  stateColor: string
  shopsCount: number
  isActive: boolean
  countryId: string
  countryName: string
  countryCode: CountryCode
}

export interface IStateWithRegionInfo extends IState {
  regionId: string
  regionName: string
  regionColor: string
  states?: IState[]
}

export interface IStatesResponse {
  states: IStateWithRegionInfo[]
}
